import { yupResolver } from '@hookform/resolvers/yup';
import {
  Assistant,
  Checklist,
  SpaceDashboard,
  Translate,
} from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import apis from 'api/api';
import EditDialogWrapper from 'components/Dialog/DialogWrapper';
import LoadingOverlay from 'components/LoadingOverlay';
import { Tab } from 'components/Tab';
import { useApi } from 'hooks';
import isEqual from 'lodash/isEqual';
import { UserProvider } from 'pages/Settings/context';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDialogStore, useItemStore } from 'store';
import useStore from 'store/useStore';
import { JourneyDetails } from '../JourneyDetails';
import { ModuleSelection } from '../ModuleSelection';
import { ModuleTranslation } from '../ModuleTranslation';
import { journeyDetails } from './schema';
import { useJourney } from './useJourney';
import { areModuleArraysEqual } from './utils';

export const EditDialog = ({ forItem = '' }) => {
  const [aiModules, setAIModules] = useState([]);
  const { editJourney } = useJourney();
  const [aiGenModuleDetails, setAiGenModuleDetails] = useState([]);
  const dialogState = useDialogStore((state) => state.editDialog);
  const deleteItemStore = useItemStore((state) => state.deleteItem);
  const { setDataUpdated, dataUpdated } = useStore();
  const journeyId = dialogState?.data?._id;

  const methods = useForm({
    resolver: yupResolver(journeyDetails),
    mode: 'onChange',
  });

  const {
    call: getJourneyDetails,
    data: journeyInfo,
    isLoading: journeyIsLoading,
  } = useApi({
    fetcher: apis.getJourney,
  });

  const {
    call: createJourneyModules,
    data: aiGenModules,
    isLoading: isAiModulesCreating,
  } = useApi({
    fetcher: apis.createJourneyModules,
    onSuccess: (res) => {
      setAIModules([{ moduleId: res?.aiModuleId }]);
    },
  });

  const loading = journeyIsLoading; //|| isModuleDetailsLoading;

  useEffect(() => {
    const onEditPage = journeyInfo;
    if (onEditPage) {
      if (onEditPage?.aiModuleId) {
        setAIModules([{ moduleId: onEditPage?.aiModuleId }]);
      }
    }
  }, [journeyInfo]);

  // TODO : Problem
  useEffect(() => {
    if (journeyId) {
      getJourneyDetails({ journeyId });
    }
  }, [journeyId]);

  const handleEdit = async (value) => {
    await editJourney(value);
    methods.reset();
  };

  const handleClickModuleRecom = async () => {
    const currentSelectedModules = useItemStore?.getState()?.items;
    const isModulesIdentical = areModuleArraysEqual(
      await currentSelectedModules?.selectedModules,
      await journeyInfo?.modules
    );
    if (!isModulesIdentical) {
      await createJourneyModules({
        modules: currentSelectedModules?.selectedModules,
        journeyId: journeyInfo._id,
      });
      setDataUpdated(!dataUpdated);
    }
  };

  const handleModuleSelectionClick = useCallback(async () => {
    if (journeyId) {
      await getJourneyDetails({ journeyId });
    }
  }, [journeyId, getJourneyDetails]);

  // step 2
  const JourneyModuleSelection = memo(
    ({ selected }) => {
      return <ModuleSelection selected={selected} />;
    },
    (prevProps, nextProps) => isEqual(prevProps.selected, nextProps.selected)
  );

  // step 3
  const RecommendedModuleSelection = useCallback(() => {
    return isAiModulesCreating ? (
      <CircularProgress />
    ) : (
      <ModuleSelection aiRecommended={true} selected={aiModules} />
    );
  }, [isAiModulesCreating, aiModules]);

  const memoizedSelected = useMemo(
    () => journeyInfo?.modules || [],
    [JSON.stringify(journeyInfo?.modules)]
  );

  const TabItems = useMemo(() => {
    const baseItems = [
      {
        label: 'Journey Details',
        value: 'journey_details',
        icon: <Checklist />,
        content: <JourneyDetails />,
      },
      {
        label: 'Module Selection',
        value: 'module_selection',
        icon: <SpaceDashboard />,
        content: <JourneyModuleSelection selected={memoizedSelected} />,
        onClick: handleModuleSelectionClick,
      },
      {
        label: 'Recommendation',
        value: 'module_recommendation',
        icon: <Assistant />,
        content: (
          <ModuleSelection
            aiRecommended={true}
            selected={aiModules}
            journeyId={journeyInfo?._id}
            moduleLoading={isAiModulesCreating}
          />
        ),
        onClick: handleClickModuleRecom,
      },
      {
        label: 'Translation',
        value: 'module_translation',
        icon: <Translate />,
        content: (
          <ModuleTranslation
            journeyDetails={journeyInfo}
            translatedData={aiGenModuleDetails}
            language={journeyInfo?.targetLanguage}
          />
        ),
      },
    ];

    return baseItems;
  }, [journeyId, memoizedSelected, journeyInfo, isAiModulesCreating]);

  return (
    <UserProvider userType={'PARTNER'}>
      {loading ? <LoadingOverlay /> : null}
      <FormProvider {...methods}>
        <EditDialogWrapper
          forItem={forItem}
          onSubmit={methods.handleSubmit(handleEdit)}
          sx={{ width: '75vw' }}
          onClose={() => {
            methods.reset();
            deleteItemStore({ key: 'selectedModules' });
          }}
        >
          <Tab tabItems={TabItems} />
        </EditDialogWrapper>
      </FormProvider>
    </UserProvider>
  );
};
