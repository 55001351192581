import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';

const FormFields = ({
  type,
  name,
  formData = {},
  handleChange,
  tagInput,
  options = [],
  label,
  placeholder,
  errors = {},
  props,
  readOnly = false,
  disabled,
  rows = 4,
  canSearch = false,
}) => {
  switch (type) {
    case 'textfield':
      return (
        <TextField
          fullWidth
          variant="outlined"
          label={label}
          value={formData[name] || ''}
          onChange={(e) => handleChange(name, e.target.value)}
          error={!!errors[name]}
          helperText={errors[name]}
          InputProps={{ readOnly: readOnly }}
          {...props}
          disabled={disabled}
        />
      );
    case 'textarea':
      return (
        <TextField
          fullWidth
          multiline
          rows={rows}
          variant="outlined"
          label={label}
          value={formData[name] || ''}
          onChange={(e) => handleChange(name, e.target.value)}
          error={!!errors[name]}
          helperText={errors[name]}
          InputProps={{ readOnly: readOnly }}
          {...props}
          disabled={disabled}
        />
      );
    case 'taginput':
      return (
        <Autocomplete
          multiple
          freeSolo={tagInput}
          options={options}
          value={formData[name] || []}
          onChange={(_, newValue) => handleChange(name, newValue)}
          renderTags={(value, getTagProps) =>
            value.map((option, idx) => (
              <Chip
                key={idx}
                {...getTagProps({ index: idx })}
                label={option}
                sx={{
                  backgroundColor: '#DAE8E7',
                  m: 0.5,
                  '& .MuiChip-deleteIcon': {
                    color: '#5B706F',
                  },
                }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              placeholder={placeholder}
              error={!!errors[name]}
              helperText={errors[name]}
            />
          )}
          {...props}
          readOnly={readOnly}
          disabled={disabled}
        />
      );

    case 'checkbox':
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={formData[name] || false}
              onChange={(e) => handleChange(name, e.target.checked)}
              name={name}
              {...props}
              readOnly={readOnly}
              disabled={disabled}
            />
          }
          label={label}
        />
      );

    case 'datepicker':
      return (
        <TextField
          fullWidth
          label={label}
          type="date"
          InputLabelProps={{ shrink: true }}
          value={formData[name] || ''}
          onChange={(e) => handleChange(name, e.target.value)}
          error={!!errors[name]}
          helperText={errors[name]}
          {...props}
          InputProps={{ readOnly: readOnly }}
          disabled={disabled}
        />
      );

    case 'select':
      return canSearch ? (
        <FormControl fullWidth error={!!errors[name]}>
          <Autocomplete
            disablePortal
            options={options}
            value={
              options.find((option) => option.value === formData[name]) || null
            }
            onChange={(_, newValue) =>
              handleChange(name, newValue?.value || '')
            }
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={!!errors[name]}
                helperText={errors[name]}
              />
            )}
            disabled={disabled}
            readOnly={readOnly}
            {...props}
            PaperComponent={({ children }) => (
              <Paper
                style={{
                  marginTop: '5px',
                  border: '1px solid #C0BEBEFF',
                  borderRadius: '4px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                }}
              >
                {children}
              </Paper>
            )}
          />
        </FormControl>
      ) : (
        <FormControl fullWidth error={!!errors[name]}>
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <Select
            labelId={`${name}-label`}
            value={formData[name] || ''}
            onChange={(e) => handleChange(name, e.target.value)}
            label={label}
            {...props}
            readOnly={readOnly}
            disabled={disabled}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {errors[name] && (
            <p style={{ color: 'red', fontSize: '0.8rem' }}>{errors[name]}</p>
          )}
        </FormControl>
      );

    default:
      return null;
  }
};

export default FormFields;
