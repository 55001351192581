import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useBirdsEyeDataStore = create(
  persist(
    (set, get) => ({
      // BirdsEye Statistics
      statistics: [],
      setStatistics: (newData) => set({ statistics: newData }),
      clearStatistics: () => set({ statistics: [] }),
      getStatistics: () => get().statistics,

      // BirdsEye Average Score Chart
      averageScoreChart: [],
      setAverageScoreChart: (newData) => set({ averageScoreChart: newData }),
      clearAverageScoreChart: () => set({ averageScoreChart: [] }),
      getAverageScoreChart: () => get().averageScoreChart,

      // BirdsEye Comparison Chart
      comparisonChart: [],
      setComparisonChart: (newData) => set({ comparisonChart: newData }),
      clearComparisonChart: () => set({ comparisonChart: [] }),
      getComparisonChart: () => get().comparisonChart,

      // Clear all data
      clearAllData: () =>
        set({
          statistics: [],
          averageScoreChart: [],
          comparisonChart: [],
        }),
    }),
    {
      name: 'birds-eye-data-store',
      version: 1, // Added version for better migration handling
    }
  )
);

export default useBirdsEyeDataStore;
