import { useEffect, useState } from 'react';
import styles from '../styles/Business.module.css';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import organizationAPI from 'api/api';
import { CustomTextField } from 'components/TextField';

const RemoveModal = ({ open, setOpen, deleteProblem, index }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className={styles.modal_title}
        >
          <h5>Remove Prompt</h5>
          <p>Are you sure you want to remove this prompt?</p>
        </Typography>

        <div className={styles.modal_buttons}>
          <button onClick={() => setOpen(false)} className={styles.cancel}>
            Cancel
          </button>
          <button
            onClick={() => {
              deleteProblem(index);
              setOpen(false);
            }}
            className={styles.remove}
          >
            Remove
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const Workflow = ({
  index,
  text,
  workflowId,
  canvasId,
  getWorkflowItem,
  type,
  title,
}) => {
  const [problem, setProblem] = useState(text);
  const [open, setOpen] = useState(false);
  const [disableInput, setDisableInput] = useState(true);
  const [originalText] = useState(text);

  const handleClose = () => {
    setProblem(originalText);
    setDisableInput(true);
  };
  const updateWorkflow = () => {
    const data = {
      type: type,
      title: title,
      body: problem,
    };
    const response = organizationAPI.updateCanvasWorkflowItem(
      data,
      canvasId,
      workflowId
    );
    getWorkflowItem();
    setDisableInput(true);
  };

  const deleteWorkflow = async (index) => {
    const response = await organizationAPI.deleteCanvasWorkflowItem(
      canvasId,
      workflowId
    );
    getWorkflowItem();
  };

  return (
    <>
      <RemoveModal
        open={open}
        setOpen={setOpen}
        deleteProblem={deleteWorkflow}
        index={index}
      />

      <CustomTextField
        id="input-with-icon-textfield"
        label={title}
        placeholder={`Prompt ${index + 1}`}
        disabled={disableInput}
        multiline
        value={problem}
        onChange={(e) => setProblem(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!disableInput && (
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={updateWorkflow}
                >
                  <DoneIcon />
                </IconButton>
              )}

              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={
                  disableInput ? () => setDisableInput(false) : handleClose
                }
              >
                {disableInput ? <EditIcon /> : <CloseIcon />}
              </IconButton>
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={() => setOpen(true)}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        className={styles.input}
      />
    </>
  );
};

const Revenue = ({ index, text, saveProblem, closeProblem, deleteProblem }) => {
  const [problem, setProblem] = useState(text);
  const [open, setOpen] = useState(false);
  const title = `REVENUE STREAMS ${index + 1}`;

  return (
    <>
      <RemoveModal
        open={open}
        setOpen={setOpen}
        deleteProblem={deleteProblem}
        index={index}
      />
      <CustomTextField
        id="input-with-icon-textfield"
        label={title}
        placeholder={`Prompt ${index + 1}`}
        multiline
        value={problem}
        onChange={(e) => setProblem(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={() =>
                  saveProblem(index, problem, title, 'revenueStream')
                }
              >
                <DoneIcon />
              </IconButton>

              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={() => setOpen(true)}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        className={styles.input}
      />
    </>
  );
};

const RevenueCard = ({ canvasId, workflowList, getCanvas }) => {
  const [problemList, setProblemList] = useState([]);
  const navigate = useNavigate();
  const [totalProblemCount, setTotalProblemCount] = useState(0);

  useEffect(() => {
    setTotalProblemCount(
      workflowList.filter((item) => item.type === 'revenueStream').length +
        problemList.length
    );
  }, [workflowList, problemList]);

  const addProblem = () => {
    const tempProblemList = [...problemList];
    const tempProblem = {
      problem: '',
      status: true,
    };
    tempProblemList.push(tempProblem);
    setProblemList(tempProblemList);
  };

  const editProblem = (index) => {
    const tempProblemList = [...problemList];
    tempProblemList[index].status = true;
    setProblemList(tempProblemList);
  };

  const saveProblem = async (index, value, title, type) => {
    if (value === '') return;
    const tempProblemList = [...problemList];
    tempProblemList[index].problem = value;
    tempProblemList[index].status = false;
    setProblemList(tempProblemList);

    const data = {
      type: type,
      title: title,
      body: value,
    };
    const response = await organizationAPI.addCanvasWorkflowItem(
      data,
      canvasId
    );

    const workflowResponse = getCanvas();
  };

  const deleteProblem = (index) => {
    const tempProblemList = [...problemList];
    tempProblemList.splice(index, 1);
    setProblemList(tempProblemList);
  };

  const closeProblem = (index) => {
    if (problemList[index].problem === '') {
      deleteProblem(index);
      return;
    } else {
      const tempProblemList = [...problemList];
      tempProblemList[index].status = false;
      setProblemList(tempProblemList);
    }
  };

  const handleCanvas = () => {
    navigate(`/student/learner/canvas/`);
  };

  return (
    <>
      <div className={styles.container_head}>
        <h6>Revenue Streams</h6>
        <button className={styles.iteration} onClick={handleCanvas}>
          Back to Canvas
        </button>
      </div>
      <div className={styles.border}></div>

      <div className={styles.container_body}>
        <p>
          List out the different revenue streams for your business. Consider
          what value are your customers really willing to pay, what they
          currently pay, how are they currently paying, and how they would
          prefer to pay. How much does each Revenue Stream contribute to overall
          revenues?
        </p>

        <div className={styles.container_body_input}>
          {workflowList.map((item) => {
            if (item.type === 'revenueStream') {
              return (
                <Workflow
                  index={item.title.split(' ')[1] - 1}
                  workflowId={item._id}
                  type={item.type}
                  title={item.title}
                  text={item.body}
                  canvasId={canvasId}
                  getWorkflowItem={getCanvas}
                />
              );
            }
          })}

          {problemList.map((item, index) => {
            if (item.status) {
              return (
                <Revenue
                  index={index}
                  text={item.problem}
                  saveProblem={saveProblem}
                  closeProblem={closeProblem}
                  deleteProblem={deleteProblem}
                />
              );
            }
          })}
          {/* {totalProblemCount < 1 && ( */}
          <div className={styles.add} onClick={() => addProblem()}>
            <AddOutlinedIcon />
            <a>Add New Revenue Stream</a>
          </div>
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default RevenueCard;
