import LinkOffIcon from '@mui/icons-material/LinkOff';
import Box from '@mui/material/Box';
import organizationAPI from 'api/api';
import { EmptyState, UploadAudio, Widget } from 'components/CustomMUI';
import LoadingOverlay from 'components/LoadingOverlay';
import useModal from 'hooks/useConfirmationModal';
import useFormattedData from 'hooks/useFormattedData';
import useNotification from 'hooks/useNotification';
import prettyMilliseconds from 'pretty-ms';
import { useEffect, useState } from 'react';
import useNotificationStore from 'store/useNotificationStore';
import { useApi } from './../../../../hooks/useApi';
import BirdsEyeStatisticFilter from './BirdsEyeStatisticFilter';
import ComparisonCharts from './ComparisonCharts';
import SalesRepresentativesTable from './SalesRepresentativesTable';
import styles from './styles/Style.module.css';

const AdminLeaderboard = () => {
  const { show, NotificationComponent } = useNotification();
  const { notification } = useNotificationStore();
  const { openModal, Modal } = useModal();
  const [widgetData, setWidgetData] = useState(null);
  const { formattedNum } = useFormattedData();
  const { call: getAdminDashboardBreakdownCall, isLoading: loading } = useApi({
    fetcher: organizationAPI.getAdminDashboardBreakdown,
  });

  useEffect(() => {
    const fetchAdminDashboardBreakdownData = async () => {
      try {
        const response = await getAdminDashboardBreakdownCall();
        if (response && response.data) {
          setWidgetData(response.data);
        }
      } catch (error) {
        console.error('Error fetching personas', error);
      }
    };

    fetchAdminDashboardBreakdownData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (notification) {
      show(notification.message, notification.type);
    }
  }, [notification, show]);

  const handleDeleteClick = () => {
    openModal({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this item?',
      icon: LinkOffIcon,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      onConfirm: () => console.log('Confirmed deletion'),
      onCancel: () => {
        console.log('Confirmed Canceled');
      },
    });
  };

  return (
    <>
      {!notification && loading ? <LoadingOverlay></LoadingOverlay> : null}
      {notification && !loading ? (
        <NotificationComponent
          notification={notification.message}
          type={notification.type}
        />
      ) : null}
      <div className={styles.main}>
        <Modal />
        <div className={styles.content}>
          <div className={styles.navbar_container}>
            <div className={styles.navbar}>
              <h1>Dashboard </h1>
              <div className={styles.nav_right}>
                <UploadAudio />
                {/* <button className={styles.nav_btn} onClick={handleDeleteClick}>
                  <LinkOffIcon fontSize="sm" />
                  <p>Disconnect Salesforce</p>
                  <CloudIcon fontSize="sm" sx={{ color: '#00A1E0' }} />
                </button> */}
              </div>
            </div>
            <Box
              sx={{
                border: '1px solid var(--global-outline-grey, #dae8e7)',
                marginTop: '10px',
                marginBottom: '30px',
              }}
            />
          </div>
          {/* <div className={styles.header_action_bar}>
            <div className={styles.check_button_group}>
              <CustomCheckbox label={'AI Roleplay Calls'} color={'#00706C'} />{' '}
              <CustomCheckbox label={'Customer Calls'} color={'#00706C'} />
            </div>
            <CustomDatepicker label={'Select date range'} />
            <SearchBar placeholder="Search by name" />
          </div> */}
          <Box
            sx={{
              width: '99%',
              display: 'flex',
              flexDirection: 'column',
              gap: '40px',
            }}
          >
            {widgetData ? (
              <Box
                sx={{
                  display: 'grid',
                  width: '100%',
                  gridTemplateColumns: {
                    xs: '1fr', // Mobile: full width
                    sm: `repeat(2, calc((100% - 24px) / 2))`, // Tablet: 2 columns
                    md: `repeat(3, calc((100% - 48px) / 3))`, // Laptop: 3 columns
                    lg: `repeat(4, calc((100% - 290px) / 4))`, // Desktop: 4 columns
                  },
                  gap: {
                    xs: '24px', // Mobile
                    sm: '24px', // Tablet
                    md: '24px', // Laptop
                    lg: '80px', // Desktop
                  },
                  mx: 'auto', // Center the grid
                  boxSizing: 'border-box',
                  '& > *': {
                    // Style for Widget components
                    minWidth: 0, // Prevent overflow
                    width: '100%', // Take full width of grid cell
                    height: '160px', // Fixed height for widgets
                  },
                }}
              >
                <Widget
                  title="Team Members"
                  info={widgetData.teamMembers.value}
                  percentage={formattedNum(widgetData.teamMembers.percentage)}
                  increase={widgetData.teamMembers.hasIncreased}
                />
                <Widget
                  title="Total Calls"
                  info={widgetData.totalCalls.value}
                  percentage={formattedNum(widgetData.totalCalls.percentage)}
                  increase={widgetData.totalCalls.hasIncreased}
                />
                <Widget
                  title="Total AI Calls"
                  info={widgetData.totalAiCalls.value}
                  percentage={formattedNum(widgetData.totalAiCalls.percentage)}
                  increase={widgetData.totalAiCalls.hasIncreased}
                />
                <Widget
                  title="Total Customer Calls"
                  info={widgetData.totalCustomerCalls.value}
                  percentage={formattedNum(
                    widgetData.totalCustomerCalls.percentage
                  )}
                  increase={widgetData.totalCustomerCalls.hasIncreased}
                />
                <Widget
                  title="Total Call Duration"
                  info={prettyMilliseconds(widgetData.totalCallDuration.value)}
                  percentage={formattedNum(
                    widgetData.totalCallDuration.percentage
                  )}
                  increase={widgetData.totalCallDuration.hasIncreased}
                />
                <Widget
                  title="Highest Score"
                  info={widgetData.highestScore.value}
                  percentage={formattedNum(widgetData.highestScore.percentage)}
                  increase={widgetData.highestScore.hasIncreased}
                />
                <Widget
                  title="Lowest Score"
                  info={widgetData.lowestScore.value}
                  percentage={formattedNum(widgetData.lowestScore.percentage)}
                  increase={widgetData.lowestScore.hasIncreased}
                />
                <Widget
                  title="Average Score"
                  info={widgetData.averageScore.value}
                  percentage={formattedNum(widgetData.averageScore.percentage)}
                  increase={widgetData.averageScore.hasIncreased}
                />
              </Box>
            ) : (
              <EmptyState />
            )}
            <BirdsEyeStatisticFilter />
            <ComparisonCharts />
            <SalesRepresentativesTable />
          </Box>
        </div>
      </div>
    </>
  );
};

export default AdminLeaderboard;
