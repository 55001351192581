import {
  Add,
  Edit,
  RemoveCircleOutline,
  Replay,
  Search,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { apis } from 'api/api';
import DeleteDialog from 'components/Dialog/DeleteDialog';
import { ActionsDropdown } from 'components/Table/TableActions';
import { useApi } from 'hooks';
import { useTableSearch } from 'pages/Settings/context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';
import { useDialogStore, useItemStore } from 'store/useStore';
import { StoreKeys } from 'utils';
import { getOriginalZonedDate } from 'utils/date';
import { DataTable } from '../../components/Table/Table';
import { TableSkeleton } from '../../components/Table/TableSkeleton';
import { colorPalette } from '../../theme/colors';
import { EditDialog } from './common/EditDialog';
// import DeleteDialog from './common/DeleteDialog';
// import { EditDialog } from './common/EditDialog';

const ModuleListTableColumns = [
  { id: 'title', label: 'Journey Name' },
  { id: 'partnerName', label: 'Partner' },
  { id: 'duration', label: 'Duration' },
  { id: 'formatOfInstruction', label: 'Format' },
  { id: 'supportStructure', label: 'Support' },
  { id: 'language', label: 'Language' },
  { id: 'createdAt', label: 'Created At' },

  { id: 'actions', label: 'Actions' },
];
export const JourneyEditDialogOpenKey = 'journeyDetails';
export const JourneyList = () => {
  const user = useAuthStore((state) => state.loggedUser);
  const setDeleteDialog = useDialogStore((state) => state.setDeleteDialog);
  const setEditDialog = useDialogStore((state) => state.setEditDialog);

  const itemStore = useItemStore((state) => state.items);
  const addItemToStore = useItemStore((state) => state.addItem);
  const updateItemStore = useItemStore((state) => state.updateItem);

  const navigate = useNavigate();
  const { call: fetchJourneys, isLoading: isFetching } = useApi({
    fetcher: () => {
      return apis.getJourneyList({ organizationId: user.organization });
    },
    onSuccess: (updatedData) => {
      addItemToStore({ key: StoreKeys.JOURNEYS, value: updatedData });
    },
  });

  const { call: deleteJourney } = useApi({
    fetcher: apis.deleteJourney,
    successMessage: 'Journey deleted successfully',
  });

  useEffect(() => {
    fetchJourneys();
  }, []);

  const searchFields = ['title'];
  const { setSearchTerm, filteredData } = useTableSearch({
    data: itemStore[StoreKeys.JOURNEYS],
    searchFields,
    filter: (item) => item.status !== 'archive',
  });

  const actions = (data) => {
    return [
      ...(data.status === 'draft'
        ? [
            {
              label: 'View/Edit Details',
              icon: <Edit color="success" />,
              onClick: () => {
                setEditDialog({
                  isOpen: true,
                  data,
                  forItem: JourneyEditDialogOpenKey,
                });
              },
            },

            {
              label: 'Remove Journey',
              icon: <RemoveCircleOutline color="error" />,
              onClick: () =>
                setDeleteDialog({
                  isOpen: true,
                  forItem: 'Journey',
                  onOk: async () => {
                    await deleteJourney({
                      journeyId: data._id,
                    });

                    const updatedList = itemStore[StoreKeys.JOURNEYS].filter(
                      (journey) => journey._id !== data._id
                    );
                    updateItemStore({
                      key: StoreKeys.JOURNEYS,
                      value: updatedList,
                    });
                  },
                }),
            },
          ]
        : []),
      ...(data.status === 'PENDING'
        ? [
            {
              label: 'Resend Request',
              icon: <Replay color="success" />,
            },
          ]
        : []),
    ];
  };

  const adminSearchBar = (
    <Stack direction="row" spacing={1}>
      <FormControl size="small">
        <OutlinedInput
          id="outlined-adornment-amount"
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
      </FormControl>
    </Stack>
  );

  const header = (
    <Stack
      direction={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">Journeys</Typography>
    </Stack>
  );

  return (
    <Stack direction={'column'} gap={5}>
      {header}
      <Divider />
      <Box
        sx={{
          background: colorPalette.background.paper,
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Stack direction="column" spacing={5}>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Button
              onClick={() => {
                navigate('/journey/create');
              }}
              variant="contained"
              startIcon={<Add />}
            >
              New Journey
            </Button>
            {adminSearchBar}
          </Stack>
          {isFetching ? (
            <TableSkeleton column={ModuleListTableColumns} />
          ) : (
            <DataTable
              columns={ModuleListTableColumns}
              sort={true}
              orderBy="createdAt"
              order="desc"
              data={filteredData?.map((item) => ({
                ...item,
                actions: <ActionsDropdown actions={actions(item)} />,
                name: `${item.firstName} ${item.lastName}`,
                createdAt: getOriginalZonedDate({
                  date: item.createdAt ?? null,
                }),
                status: (
                  <Chip
                    label={item.status}
                    variant="outlined"
                    size="small"
                    color={item.status === 'ACCEPTED' ? 'success' : 'info'}
                  />
                ),
              }))}
            />
          )}
        </Stack>
      </Box>
      <DeleteDialog />
      <EditDialog forItem={JourneyEditDialogOpenKey} />
    </Stack>
  );
};
