import { CircularProgress, Divider, Typography } from '@mui/material';
import apis from 'api/api';
import { ContentBox } from 'components/Box';
import { DataTable } from 'components/Table';
import { useApi } from 'hooks';
import { FormField } from 'pages/Settings/Tabs/common';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDialogStore } from 'store/useStore';
import { moduleOptions } from './common/const';

const SectionListTableColumns = [
  { id: 'title', label: 'Section' },
  { id: 'activity', label: 'Activity' },
  { id: 'minutes', label: 'Minutes' },
];
const dialogOpenKey = 'moduleDetails';
export const ModuleDetails = () => {
  const editDialogState = useDialogStore((state) => state.editDialog);
  const [sectionData, setSectionData] = useState([]);

  const { call: fetchModule, isLoading: isFetchingModule } = useApi({
    fetcher: apis.getModuleById,
    onSuccess: (module) => {
      setSectionData(module?.sections);
    },
  });

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    trigger,
    reset,
  } = useFormContext();

  useEffect(() => {
    reset(editDialogState.data);

    if (
      editDialogState.data?._id &&
      editDialogState.forItem === dialogOpenKey
    ) {
      fetchModule({ moduleId: editDialogState.data?._id });
    }
  }, [editDialogState.data]);

  const renderFormFields = () => {
    const infoFields = [
      { name: 'title', label: 'Module Title', type: 'text' },
      {
        name: 'category',
        label: 'Category',
        type: 'multiSelect',
        options: moduleOptions.category,
      },
      {
        name: 'description',
        label: 'Description',
        type: 'text',
        multiline: true,
      },

      {
        name: 'course',
        label: 'Course',
        type: 'multiSelect',
        options: moduleOptions.course,
      },
    ];

    return (
      <>
        {infoFields.map((item) => (
          <FormField
            //loading={srLoading || personasLoading}
            formType="ADD"
            key={item.name}
            field={item}
            register={register}
            errors={errors}
            watch={watch}
            hookFormUpdater={setValue}
            hookFormTrigger={trigger}
          />
        ))}
      </>
    );
  };

  const shouldShowSectionOverview = editDialogState?.isOpen;
  const sectionOverviewLoading = isFetchingModule;
  return (
    <ContentBox
      id="temp"
      sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
    >
      {renderFormFields()}
      {shouldShowSectionOverview ? (
        <>
          <Typography variant="button">Section Overview</Typography>
          <Divider sx={{ borderColor: 'primary.main' }} />
          {sectionOverviewLoading ? (
            <CircularProgress />
          ) : (
            <DataTable columns={SectionListTableColumns} data={sectionData} />
          )}
        </>
      ) : null}
    </ContentBox>
  );
};
