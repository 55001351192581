export const handleExportPPTX = async ({ id, title, call }) => {
  try {
    const response = await call(id);

    if (response?.data?.url) {
      const fileName = title ? `${title}.pptx` : 'presentation.pptx';

      const link = document.createElement('a');
      link.href = response.data.url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Failed to get the download URL');
    }
  } catch (error) {
    console.error('Error generating PPT:', error);
  }
};

export const updateChecklistItem = ({ id, isComplete, setValue }) => {
  setValue((prevItems) =>
    prevItems.map((item) => (item.id === id ? { ...item, isComplete } : item))
  );
};
