import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, LinearProgress, Paper, Typography } from '@mui/material';
import { EmptyState, FlexibleTable } from 'components/CustomMUI';
import dateFormat from 'dateformat';
import { useNavigate } from 'react-router-dom';

const UserAssignmentTable = ({ data, filter, search }) => {
  const navigate = useNavigate();

  const getActionButton = (progress, total) => {
    if (progress === 0) return 'Begin';
    if (progress === total) return 'Review';
    return 'Continue';
  };

  const getActionButtonColor = (action) => {
    return action === 'Begin' ? '#1A8753' : '#006298';
  };

  const handleNavigateToAssignmentViewPage = (assignmentId) => {
    navigate(`/convo/assignment/details/${assignmentId}`);
  };

  const filteredData = data
    .filter((row) => filter === 'all' || filter.includes(row.status))
    .filter((row) => {
      if (!search && filter === 'all') return true;

      const searchLower = search.toLowerCase();
      const assignmentNameValid = row.assignmentName
        ? row.assignmentName.toLowerCase().includes(searchLower)
        : false;
      const dueDateValid = row.dueDate
        ? row.dueDate.toLowerCase().includes(searchLower)
        : false;
      const assigneesValid =
        row.assignedTo &&
        row.assignedTo.some((assignee) =>
          assignee.userName.toLowerCase().includes(searchLower)
        );

      return assignmentNameValid || dueDateValid || assigneesValid;
    });

  const columns = [
    {
      header: 'Assignment Name',
      key: 'assignmentName',
      sortable: true,
      render: (value) => (
        <Typography
          sx={{
            color: '#101828',
            fontSize: '14px',
            textTransform: 'capitalize',
          }}
        >
          {value}
        </Typography>
      ),
    },
    {
      header: 'Due Date',
      key: 'dueDate',
      sortable: true,
      render: (value) => (
        <Typography sx={{ color: '#101828', fontSize: '14px' }}>
          {dateFormat(value, 'mmm d, yyyy')}
        </Typography>
      ),
    },
    {
      header: 'Status',
      key: 'status',
      render: (value) => (
        <Typography
          sx={{
            backgroundColor:
              value === 'all'
                ? 'rgba(128, 128, 128, 0.5)'
                : value === 'assigned'
                ? 'rgba(64, 153, 255, 0.5)'
                : value === 'active'
                ? 'rgba(0, 200, 117, 0.5)'
                : value === 'completed'
                ? 'rgba(241, 217, 110, 0.5)'
                : value === 'overdue'
                ? 'rgba(253, 97, 97, 0.5)'
                : value === 'archived'
                ? 'rgba(128, 128, 128, 0.5)'
                : 'rgba(80, 80, 80, 0.1)',
            padding: '1px 10px',
            borderRadius: '12px',
            width: 'fit-content',
            fontSize: '14px',
            textTransform: 'capitalize',
          }}
        >
          {value}
        </Typography>
      ),
    },
    {
      header: 'Progress',
      key: 'progress',
      sortable: true,
      render: (_, row) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            width: '100%',
            maxWidth: 400,
          }}
        >
          <LinearProgress
            variant="determinate"
            value={row.percentage}
            sx={{
              width: '100%',
              height: 8,
              borderRadius: 4,
              backgroundColor: '#F2F4F7',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#1A8753',
                borderRadius: 4,
              },
              border: '1px solid #D8DDE2FF',
            }}
          />
          <Typography
            sx={{
              color: '#667085',
              fontSize: '14px',
              whiteSpace: 'nowrap',
            }}
          >
            {row.progress}
          </Typography>
        </Box>
      ),
    },
    {
      header: '',
      key: 'action',
      render: (_, row) => {
        const action = getActionButton(row.progress, row.total);
        return (
          <Button
            variant="text"
            onClick={() => handleNavigateToAssignmentViewPage(row._id)}
            sx={{
              color: getActionButtonColor(action),
              fontSize: '14px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {row.percentage === 0
              ? 'Begin'
              : row.percentage === 100
              ? 'Review'
              : 'Continue'}
            <ChevronRightIcon sx={{ fontSize: 18, color: '#1A8753' }} />
          </Button>
        );
      },
    },
  ];

  if (data && data.length > 0) {
    return (
      <Paper
        sx={{
          boxShadow: 'none',
          border: '1px solid #DAE8E7',
          borderRadius: '8px',
          padding: '10px',
        }}
      >
        <FlexibleTable
          data={filteredData}
          columns={columns}
          tableStyles={{
            tableContainer: {
              boxShadow: 'none',
              border: 'none',
            },
            headerCell: {
              color: '#344054',
              fontSize: '14px',
              fontWeight: 500,
            },
          }}
        />
      </Paper>
    );
  } else {
    return <EmptyState />;
  }
};

export default UserAssignmentTable;
