import { Dialog, DialogContent } from '@mui/material';
import LoadingOverlay from 'components/LoadingOverlay';
import { Acknowledgement } from '../Acknowledgement';
import { DemographicInfo } from '../DemographicInfo';
import { useStudentDashboardContext } from '../context/dashboardContext';

export const ConsentDialog = () => {
  const { data, userInfoLoading, loggedUserInfo } =
    useStudentDashboardContext();

  const shouldOpenDialog =
    (!data.isAcknowledged || !data.hasDemographicData) &&
    loggedUserInfo?.organizationType === 'ASSESSMENT' &&
    loggedUserInfo?.user?.role === 'STUDENT';

  return userInfoLoading ? (
    <LoadingOverlay />
  ) : (
    <Dialog
      open={shouldOpenDialog}
      maxWidth="md"
      PaperProps={{ sx: { padding: 1 } }}
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Acknowledgement />
        <DemographicInfo />
      </DialogContent>
    </Dialog>
  );
};
