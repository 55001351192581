import '@fontsource/noto-sans/400.css';
import '@fontsource/noto-sans/700.css';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const ExportToPDF = (formattedData) => {
  const doc = new jsPDF();

  const renderText = (text, x, y, options = {}) => {
    const {
      fontSize = 12,
      fontStyle = 'normal',
      color = [0, 0, 0],
      align = 'left',
      maxWidth,
    } = options;

    doc.setFontSize(fontSize);
    doc.setTextColor(...color);

    const textStr = String(text || '');

    const isRTL = Array.from(textStr).some((char) => {
      const code = char.charCodeAt(0);
      return (
        (code >= 0x0590 && code <= 0x05ff) ||
        (code >= 0x0600 && code <= 0x06ff) ||
        (code >= 0x0750 && code <= 0x077f)
      );
    });

    if (isRTL) {
      doc.text(textStr, align === 'left' ? x + (maxWidth || 0) : x, y, {
        align: align === 'left' ? 'right' : align,
      });
    } else {
      if (Array.isArray(text)) {
        text.forEach((line, index) => {
          const lineStr = String(line || '');
          doc.text(lineStr, x, y + index * (fontSize * 0.5), {
            align,
            maxWidth,
          });
        });
      } else {
        doc.text(textStr, x, y, {
          align,
          maxWidth,
        });
      }
    }

    return doc.getTextDimensions(textStr);
  };

  formattedData.sections?.forEach((section, index) => {
    if (index > 0) doc.addPage();

    let yPosition = 20;

    if (section.header) {
      renderText(section.header, 20, yPosition, {
        fontSize: 20,
        color: [0, 58, 93],
        fontStyle: 'bold',
      });
    }

    section.content?.forEach((item) => {
      yPosition += 15;

      if (yPosition > 280) {
        doc.addPage();
        yPosition = 20;
      }

      if (item.type === 'table') {
        doc.autoTable({
          startY: yPosition,
          head: item.headers,
          body: item.data,
          styles: { fontSize: 10 },
          headStyles: { fillColor: [0, 224, 213] },
          margin: { left: 20 },
        });
        yPosition = doc.lastAutoTable.finalY + 10;
      } else if (item.type === 'subsection') {
        const title = `Sub-Section ${item.index + 1}: ${item.title || ''}`;
        renderText(title, 25, yPosition, {
          fontSize: 12,
          fontStyle: 'bold',
          color: [91, 112, 111],
        });

        yPosition += 10;

        const maxWidth = 160;
        const contentStr = String(item.content || '');
        const contentLines = doc.splitTextToSize(contentStr, maxWidth);

        if (yPosition + contentLines.length * 7 > 280) {
          doc.addPage();
          yPosition = 20;
        }

        renderText(contentLines, 25, yPosition, {
          fontSize: 10,
          maxWidth: maxWidth,
        });

        yPosition += contentLines.length * 7 + 10;
      } else {
        const textDimensions = renderText(item.text, item.x || 20, yPosition, {
          fontSize: item.fontSize || 12,
          fontStyle: item.fontStyle || 'normal',
          color: item.color || [0, 0, 0],
          align: item.align || 'left',
          maxWidth: item.maxWidth || 170,
        });

        yPosition += textDimensions.h;
      }
    });
  });

  doc.save(formattedData.filename || 'exported-content.pdf');
};

export default ExportToPDF;
