import {
  ContentCopy as CopyIcon,
  Refresh as RefreshIcon,
  ThumbDownAlt as ThumbDownIcon,
  ThumbUpAlt as ThumbUpIcon,
} from '@mui/icons-material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CommentIcon from '@mui/icons-material/Comment';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';

const AIAnalysisDrawer = ({ open, onClose }) => {
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '',
  });

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      open: true,
      message: 'Copied to clipboard!',
    });
  };

  const handleThumbsUp = () => {
    setSnackbar({
      open: true,
      message: 'Thank you for your feedback!',
    });
  };

  const handleThumbsDown = () => {
    setSnackbar({
      open: true,
      message: "Thanks for letting us know. We'll improve.",
    });
  };

  const handleRefresh = () => {
    setSnackbar({
      open: true,
      message: 'Regenerating analysis...',
    });
  };

  const handleComments = () => {
    setSnackbar({
      open: true,
      message: 'Comments feature coming soon!',
    });
  };

  const handleSnackbarClose = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const renderAnalysisSection = (title, content) => (
    <Box sx={{ mb: 3 }}>
      <Typography
        variant="subtitle1"
        sx={{
          mb: 1,
          color: 'text.secondary',
          fontWeight: 500,
        }}
      >
        {title}
      </Typography>
      <Box component="ul" sx={{ mt: 0, mb: 2, pl: 2 }}>
        {content.map((item, index) => (
          <Box key={index}>
            <Typography
              component="li"
              sx={{
                color: 'text.secondary',
                mb: item.subItems ? 1 : 0.5,
              }}
            >
              {item.text}
            </Typography>
            {item.subItems && (
              <Box component="ul" sx={{ pl: 2 }}>
                {item.subItems.map((subItem, subIndex) => (
                  <Typography
                    key={subIndex}
                    component="li"
                    sx={{
                      color: 'text.secondary',
                      mb: 0.5,
                    }}
                  >
                    {subItem}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );

  const analysisData = {
    'Call Volume Surge': [
      {
        text: 'Total calls made increased by 20% compared to the previous week.',
        subItems: [
          '3,000 calls logged this week versus 2,500 calls last week.',
          'Significant spikes noted on Monday and Wednesday, indicating potential marketing campaign effects.',
        ],
      },
      {
        text: 'The increase suggests greater customer engagement or potential issues requiring more assistance.',
      },
    ],
    'Caller Score Improvement': [
      {
        text: 'Average caller score improved by 15 points week-over-week.',
        subItems: [
          "Last week's average: 78, this week's average: 93.",
          'Increased training sessions may have contributed to better scores.',
        ],
      },
      {
        text: 'Feedback loops implemented this week likely enhanced service quality.',
      },
    ],
    'High Performer Recognition': [
      {
        text: 'Agents in the top quartile averaged 25% higher scores.',
        subItems: [
          'Top quartile average: 110, compared to overall average: 93.',
          'These agents handled 35% more calls than their peers.',
        ],
      },
      {
        text: 'Recognizing and rewarding top performers could further motivate the team.',
      },
    ],
    'Peak Hours Analysis': [
      {
        text: 'Call surges concentrated during peak hours: 10 AM - 12 PM and 3 PM - 5 PM.',
        subItems: [
          '40% of calls occurred during these hours.',
          'Staffing adjustments during these times might optimize call handling.',
        ],
      },
      {
        text: 'Alignment with customer availability suggests effectiveness of timing strategies.',
      },
    ],
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: '800px',
            p: 3,
          },
        }}
      >
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: '24px',
                fontWeight: 300,
                fontFamily: 'Lexend',
                color: '#141418',
              }}
            >
              AI Analysis
            </Typography>
            <Stack direction="row" spacing={1}>
              <Button
                startIcon={<KeyboardArrowLeftIcon />}
                variant="outlined"
                size="small"
                onClick={onClose}
                sx={{
                  color: '#5B706F',
                  border: '1px solid #5B706F',
                  padding: '6px 14px',
                }}
              >
                Return
              </Button>
              <Button
                startIcon={<CommentIcon />}
                variant="outlined"
                size="small"
                onClick={handleComments}
                sx={{
                  color: '#5B706F',
                  border: '1px solid #5B706F',
                  padding: '6px 14px',
                }}
              >
                Comments
              </Button>
            </Stack>
          </Box>
          <Divider
            sx={{ border: '1px solid #dae8e7', my: '10px', mb: '30px' }}
          />
          {/* AI Analysis Content */}
          <Box
            sx={{
              flex: 1,
              overflow: 'auto',
              gap: 1,
              display: 'flex',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Box sx={{ mb: 4 }}>
              <Box
                sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 1 }}
              >
                <AutoAwesomeIcon color="primary" />
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  Analyzer AI
                </Typography>
                <Box sx={{ flex: 1 }} />
                <IconButton
                  size="small"
                  onClick={() =>
                    handleCopy(JSON.stringify(analysisData, null, 2))
                  }
                >
                  <CopyIcon />
                </IconButton>
                <IconButton size="small" onClick={handleThumbsUp}>
                  <ThumbUpIcon />
                </IconButton>
                <IconButton size="small" onClick={handleThumbsDown}>
                  <ThumbDownIcon />
                </IconButton>
                <IconButton size="small" onClick={handleRefresh}>
                  <RefreshIcon />
                </IconButton>
              </Box>

              {/* Analysis Sections */}
              <Box sx={{ pl: 4 }}>
                {Object.entries(analysisData).map(([title, content]) =>
                  renderAnalysisSection(title, content)
                )}
              </Box>
            </Box>

            {/* User Question */}
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <Avatar
                sx={{ bgcolor: 'grey.200', height: 30, width: 30 }}
                src="/user.jpg"
              />

              <Box sx={{ flex: 1 }}>
                <Typography variant="subtitle2">You</Typography>
                <Typography color="text.secondary">
                  What do you mean by 'current financial obligations'?
                </Typography>
              </Box>
            </Box>

            {/* AI Response */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <AutoAwesomeIcon color="primary" />
              <Box sx={{ flex: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="subtitle2">Analyzer AI</Typography>
                  <Box sx={{ flex: 1 }} />
                  <IconButton
                    size="small"
                    onClick={() => handleCopy('Thinking...')}
                  >
                    <CopyIcon />
                  </IconButton>
                  <IconButton size="small" onClick={handleThumbsUp}>
                    <ThumbUpIcon />
                  </IconButton>
                  <IconButton size="small" onClick={handleThumbsDown}>
                    <ThumbDownIcon />
                  </IconButton>
                  <IconButton size="small" onClick={handleRefresh}>
                    <RefreshIcon />
                  </IconButton>
                </Box>
                <Typography color="text.secondary" sx={{ mt: 1 }}>
                  Thinking...
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: 'white',
            color: 'black',
          },
        }}
      />
    </>
  );
};

export default AIAnalysisDrawer;
