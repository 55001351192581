import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import apis from 'api/api';
import { AIAnalysisDrawer, RaderChartLegend } from 'components/CustomMUI';
import LoadingOverlay from 'components/LoadingOverlay';
import { useApi } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  YAxis,
} from 'recharts';
import useBirdsEyeDataStore from 'store/useBirdsEyeStatisticStore';

const styles = {
  cellStyle: {
    width: '150px',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '16px',
  },
  innerCellStyle: {
    width: '100%',
    overflowX: 'auto',
  },
  headerText: {
    color: '#243030',
    fontFamily: 'Lexend',
    fontWeight: 300,
  },
  graphButton: (isActive) => ({
    color: isActive ? 'rgb(0, 128, 128)' : 'text.secondary',
    borderBottom: isActive ? '2px solid rgb(0, 128, 128)' : 'none',
    borderRadius: 0,
    px: 2,
  }),
  paper: {
    padding: '20px',
    boxShadow: 'none',
    border: '1px solid #DAE8E7',
    borderRadius: '8px',
    width: '100%',
  },
  toggleButton: {
    mr: 1,
    color: '#5B706F',
    border: '1px solid #5B706F',
    borderRadius: '5px',
    p: '6px 15px',
    '&:hover': { backgroundColor: '#D9DAD9FF' },
  },
  title: {
    color: '#243030',
    fontFamily: 'Lexend',
    fontSize: '20px',
    fontWeight: 300,
  },
};

const DataChart = ({
  data,
  graphType,
  chartHeight = 400,
  isComparisonChart = false,
}) => {
  const ChartComponent = graphType === 'line' ? AreaChart : BarChart;
  const DataComponent = graphType === 'line' ? Area : Bar;

  return (
    <ResponsiveContainer width="100%" height={chartHeight}>
      <ChartComponent data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis tickLine={false} tick={{ fill: '#666' }} />
        <Tooltip content={<CustomTooltip />} />
        <DataComponent
          type="monotone"
          dataKey="current"
          stroke="rgb(0, 128, 128)"
          fill="rgb(153, 214, 214)"
          strokeWidth={1}
          radius={graphType === 'bar' ? [4, 4, 0, 0] : 0}
        />
        {isComparisonChart && (
          <DataComponent
            type="monotone"
            dataKey="previous"
            stroke="#999"
            fill="rgb(204, 204, 204)"
            strokeWidth={1}
            radius={graphType === 'bar' ? [4, 4, 0, 0] : 0}
          />
        )}
      </ChartComponent>
    </ResponsiveContainer>
  );
};

const GraphTypeSelector = ({ graphType, setGraphType }) => (
  <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
    {['line', 'bar'].map((type) => (
      <Button
        key={type}
        variant="text"
        onClick={() => setGraphType(type)}
        sx={styles.graphButton(graphType === type)}
      >
        {type === 'line' ? 'Line Graph' : 'Bar Chart'}
      </Button>
    ))}
  </Box>
);

const CustomTooltip = ({ active, payload }) => {
  const calculateChange = useCallback((current, previous) => {
    if (current === 0 && previous === 0) {
      return { value: 0, text: 'NO CHANGE', color: '#00C3FFFF' };
    }

    const absoluteChange = current - previous;
    const percentageChange =
      previous === 0
        ? current
        : ((current - previous) / Math.abs(previous)) * 100;

    if (absoluteChange === 0)
      return { value: 0, text: 'NO CHANGE (0%)', color: '#00C3FFFF' };

    const prefix = absoluteChange > 0 ? '+' : '';
    return {
      value: absoluteChange,
      text: `${prefix}${absoluteChange.toFixed(1)} POINTS ${
        absoluteChange > 0 ? 'INCREASE' : 'DECREASE'
      } (${prefix}${percentageChange.toFixed(1)}%)`,
      color: absoluteChange > 0 ? 'success.main' : 'error.main',
    };
  }, []);

  if (!active || !payload?.length) return null;

  const currentData = payload[0].payload;
  const currentDate = currentData.date || 'N/A';
  const prevDate = currentData.prevDate || 'N/A';

  const changeResult = calculateChange(
    currentData.current,
    currentData.previous
  );

  return (
    <Box sx={{ bgcolor: '#000', color: '#fff', p: 2, borderRadius: 1 }}>
      <Typography variant="subtitle2">
        {currentDate.split('•')[0]?.trim().toUpperCase() || 'INVALID DATE'}
      </Typography>
      {[
        {
          label: currentDate.split('•')[1]?.trim() || 'CURRENT',
          value: currentData.current,
        },
        {
          label: prevDate.split('•')[1]?.trim() || 'PREVIOUS',
          value: currentData.previous,
        },
      ].map(({ label, value }, index) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              width: 10,
              height: 10,
              borderRadius: '50%',
              backgroundColor: label === 'CURRENT' ? '#6FC9C3FF' : '#959999FF',
              marginRight: 1,
            }}
          />
          <Typography key={index}>
            {label}: {(Number(value) || 0).toFixed(1)}{' '}
            {payload[0].name === 'current' ? 'calls' : '/100'}
          </Typography>
        </Box>
      ))}
      <Typography sx={{ color: changeResult.color }}>
        {changeResult.text}
      </Typography>
    </Box>
  );
};

const DateSelectDropdown = ({ value, setValue, isYear = true }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const options = useMemo(
    () => [
      'Current Week to Previous Week',
      'Current Month to Previous Month',
      ...(isYear ? ['Current Year to Previous Year'] : []),
    ],
    [isYear]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mb: 3,
        alignItems: 'center',
      }}
    >
      <Typography
        variant="h6"
        sx={{ ...styles.headerText, fontSize: '20px' }}
      />
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Select
          value={value}
          size="small"
          sx={{ minWidth: 250 }}
          onChange={(e) => setValue(e.target.value)}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <Button
          variant="outlined"
          startIcon={<AutoAwesomeIcon />}
          onClick={() => setDrawerOpen(true)}
          sx={{
            color: 'rgb(0, 128, 128)',
            borderColor: 'rgb(0, 128, 128)',
            '&:hover': {
              borderColor: 'rgb(0, 128, 128)',
              bgcolor: 'rgba(0, 128, 128, 0.1)',
            },
          }}
        >
          AI Analysis
        </Button>
      </Box>
      <AIAnalysisDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
    </Box>
  );
};

const ComparisonCharts = () => {
  const [visibleCharts, setVisibleCharts] = useState({
    averageChart: true,
    comparisonChart: true,
  });

  const [timeRanges, setTimeRanges] = useState({
    table: 'Current Week to Previous Week',
    comparison: 'Current Week to Previous Week',
    average: 'Current Week to Previous Week',
  });

  const [graphTypes, setGraphTypes] = useState({
    top: 'line',
    bottom: 'bar',
  });

  const {
    setAverageScoreChart,
    setComparisonChart,
    averageScoreChart,
    comparisonChart,
  } = useBirdsEyeDataStore();

  const { call: getAverageScoreChart, isLoading: averageChartLoading } = useApi(
    {
      fetcher: apis.getAverageScoreChart,
    }
  );

  const { call: getBirdEyeCallChart, isLoading: comparisonChartLoading } =
    useApi({
      fetcher: apis.getBirdEyeCallChart,
    });

  const loading = averageChartLoading || comparisonChartLoading;

  const toggleChart = useCallback((chartKey) => {
    setVisibleCharts((prev) => ({
      ...prev,
      [chartKey]: !prev[chartKey],
    }));
  }, []);

  const updateCharts = useCallback(async () => {
    const typeMapping = {
      'Current Week to Previous Week': 'week',
      'Current Month to Previous Month': 'month',
      'Current Year to Previous Year': 'year',
    };

    try {
      const [averageResponse, comparisonResponse] = await Promise.all([
        getAverageScoreChart(typeMapping[timeRanges.average]),
        getBirdEyeCallChart(typeMapping[timeRanges.comparison]),
      ]);

      setAverageScoreChart(averageResponse.data);
      setComparisonChart(comparisonResponse.data);
    } catch (error) {
      console.error('Failed to fetch chart data:', error);
    }
  }, [timeRanges.average, timeRanges.comparison]);

  useEffect(() => {
    updateCharts();
  }, [updateCharts]);

  const chartData = useMemo(
    () => ({
      average: averageScoreChart.map((item) => ({
        date: item.date,
        current: item.current,
        previous: item.previous,
      })),
      comparison: comparisonChart.map((item) => ({
        date: item.date,
        current: item.current,
        previous: item.previous,
      })),
    }),
    [averageScoreChart, comparisonChart]
  );

  return (
    <Box
      sx={{
        width: '98%',
      }}
    >
      {loading && <LoadingOverlay />}

      <Paper sx={{ ...styles.paper, mb: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <Typography variant="h6" sx={styles.title}>
            Average Score Comparison
          </Typography>
          <Button
            endIcon={
              visibleCharts.averageChart ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )
            }
            onClick={() => toggleChart('averageChart')}
            sx={styles.toggleButton}
          >
            {visibleCharts.averageChart ? 'Hide' : 'Show'}
          </Button>
        </Box>
        <Box sx={{ border: '1px solid #DAE8E7', marginBottom: '20px' }} />
        {visibleCharts.averageChart && (
          <Box sx={{ width: '100%' }}>
            <DateSelectDropdown
              value={timeRanges.average}
              setValue={(value) =>
                setTimeRanges((prev) => ({ ...prev, average: value }))
              }
              isYear={false}
            />
            <GraphTypeSelector
              graphType={graphTypes.top}
              setGraphType={(type) =>
                setGraphTypes((prev) => ({ ...prev, top: type }))
              }
            />
            {averageScoreChart && (
              <DataChart data={chartData.average} graphType={graphTypes.top} />
            )}
            <Box
              sx={{
                my: 2,
                ml: 7,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <RaderChartLegend color={'#6FC9C3FF'} title={'Current'} />
            </Box>
          </Box>
        )}
      </Paper>

      <Paper sx={{ ...styles.paper }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <Typography variant="h6" sx={styles.title}>
            Number of Calls Made
          </Typography>
          <Button
            endIcon={
              visibleCharts.comparisonChart ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )
            }
            onClick={() => toggleChart('comparisonChart')}
            sx={styles.toggleButton}
          >
            {visibleCharts.comparisonChart ? 'Hide' : 'Show'}
          </Button>
        </Box>
        <Box sx={{ border: '1px solid #DAE8E7', marginBottom: '20px' }} />
        {visibleCharts.comparisonChart && (
          <Box sx={{ width: '100%' }}>
            <DateSelectDropdown
              value={timeRanges.comparison}
              setValue={(value) =>
                setTimeRanges((prev) => ({ ...prev, comparison: value }))
              }
            />
            <GraphTypeSelector
              graphType={graphTypes.bottom}
              setGraphType={(type) =>
                setGraphTypes((prev) => ({ ...prev, bottom: type }))
              }
            />
            {comparisonChart && (
              <DataChart
                data={chartData.comparison}
                graphType={graphTypes.bottom}
                isComparisonChart={true}
              />
            )}
            <Box
              sx={{
                my: 2,
                ml: 7,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <RaderChartLegend color={'#6FC9C3FF'} title={'Current'} />
              <RaderChartLegend color={'#959999FF'} title={'Previous'} />
            </Box>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default ComparisonCharts;
