import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TeamIndividulaCallHistory from './TeamIndividulaCallHistory';

export const TeamCallHistoryCard = ({
  title,
  data,
  behaviors,
  callHistory,
}) => {
  return (
    <Box
      sx={{
        border: '1px solid #dae8e7',
        borderRadius: '8px',
        width: '100%',
      }}
    >
      <Box sx={{ padding: '10px 20px' }}>
        <Typography
          variant="h6"
          sx={{
            color: '#40806a',
            fontFamily: 'Lexend',
            fontSize: '16px',
            fontWeight: 500,
            whiteSpace: 'nowrap',
            my: '10px',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 2,
            width: '100%',
          }}
        >
          {/* {data &&
            data
              .filter((item) => item.type === personaType)
              .map((item, index) => (
                <TeamIndividulaCallHistory
                  data={item}
                  key={index}
                  personaID={item?._id}
                  behaviors={behaviors}
                  callHistory={callHistory}
                />
              ))} */}
          {data && (
            <TeamIndividulaCallHistory
              data={data}
              behaviors={behaviors}
              callHistory={callHistory}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TeamCallHistoryCard;
