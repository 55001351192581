import {
  Add,
  Download,
  Edit,
  RemoveCircleOutline,
  Replay,
  Search,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import apis from 'api/api';
import DeleteDialog from 'components/Dialog/DeleteDialog';
import { ActionsDropdown, DataTable, TableSkeleton } from 'components/Table';
import { useApi } from 'hooks';
import { useTableSearch } from 'pages/Settings/context';
import { useEffect } from 'react';
import { useDialogStore, useItemStore } from 'store/useStore';
import { colorPalette } from 'theme/colors';
import { StoreKeys } from 'utils';
import { UploadDialog } from './common/UploadDialog';

const AssetListTableColumns = [
  { id: 'name', label: 'Asset Name' },
  { id: 'type', label: 'Asset Type' },
  { id: 'status', label: 'Category' },
  { id: 'createdAt', label: 'Created On' },
  { id: 'uploadedBy', label: 'Created By' },

  { id: 'actions', label: 'Actions' },
];

export const AssetList = () => {
  const setEditDialog = useDialogStore((state) => state.setEditDialog);
  const setDeleteDialog = useDialogStore((state) => state.setDeleteDialog);

  const itemStore = useItemStore((state) => state.items);
  const addItemToStore = useItemStore((state) => state.addItem);
  const updateItemStore = useItemStore((state) => state.updateItem);

  const { call: fetchAssets, isLoading: isFetching } = useApi({
    fetcher: apis.getAssetList,
    onSuccess: (data) => {
      addItemToStore({ key: StoreKeys.ASSETS, value: data });
    },
  });

  const { call: deleteAsset } = useApi({
    fetcher: apis.deleteAsset,
    successMessage: 'Asset deleted successfully',
  });

  const handleDownload = ({ name, url }) => {
    if (url) {
      const a = document.createElement('a');
      a.href = url;
      a.download = name || 'downloaded-file';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  useEffect(() => {
    fetchAssets();
  }, []);

  const searchFields = ['name', 'status'];
  const { setSearchTerm, filteredData } = useTableSearch({
    data: itemStore[StoreKeys.ASSETS],
    searchFields,
  });

  const actions = (data) => {
    return [
      ...(data.status === 'draft'
        ? [
            {
              label: 'View/Edit Details',
              icon: <Edit color="success" />,
              onClick: () => {
                setEditDialog({ isOpen: true, data });
              },
            },
            {
              label: 'Download Asset',
              icon: <Download color="info" />,
              onClick: () => {
                handleDownload({ name: data.name, url: data?.document?.link });
                //setEditDialog({ isOpen: true, data });
              },
            },

            {
              label: 'Remove Asset',
              icon: <RemoveCircleOutline color="error" />,
              onClick: () => {
                setDeleteDialog({
                  isOpen: true,
                  forItem: 'Asset',
                  onOk: async () => {
                    await deleteAsset({ assetId: data._id });

                    const updatedList = itemStore[StoreKeys.ASSETS].filter(
                      (asset) => asset._id !== data._id
                    );
                    updateItemStore({
                      key: StoreKeys.ASSETS,
                      value: updatedList,
                    });
                  },
                });
              },
            },
          ]
        : []),
      ...(data.status === 'PENDING'
        ? [
            {
              label: 'Resend Request',
              icon: <Replay color="success" />,
            },
          ]
        : []),
    ];
  };

  const header = (
    <Stack
      direction={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">Assets</Typography>
    </Stack>
  );

  const adminSearchBar = (
    <Stack direction="row" spacing={1}>
      <FormControl size="small">
        <OutlinedInput
          id="outlined-adornment-amount"
          placeholder="Search"
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
      </FormControl>
    </Stack>
  );

  return (
    <Stack direction={'column'} gap={5}>
      {header}
      <Divider />
      <Box
        sx={{
          background: colorPalette.background.paper,
          padding: 2,
          borderRadius: 2,
        }}
      >
        <Stack direction="column" spacing={5}>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Button
              onClick={() => {
                setEditDialog({ isOpen: true, data: null });
              }}
              variant="contained"
              startIcon={<Add />}
            >
              New Asset
            </Button>
            {adminSearchBar}
          </Stack>
          {isFetching ? (
            <TableSkeleton column={AssetListTableColumns} />
          ) : (
            <DataTable
              columns={AssetListTableColumns}
              sort={true}
              orderBy="createdAt"
              order="desc"
              data={filteredData?.map((item) => ({
                ...item,
                actions: <ActionsDropdown actions={actions(item)} />,
                createdAt: new Date(item.createdAt).toDateString(),
                status: (
                  <Chip
                    label={item.status}
                    variant="outlined"
                    size="small"
                    color={item.status === 'ACCEPTED' ? 'success' : 'info'}
                  />
                ),
              }))}
            />
          )}
        </Stack>
      </Box>
      <DeleteDialog />
      <UploadDialog />
    </Stack>
  );
};
