import { createSortedSelectOptions } from 'utils';

export const moduleOptions = {
  category: createSortedSelectOptions([
    { name: 'Entrepreneurial Mindset', id: 'Entrepreneurial Mindset' },
    { name: 'Ideation', id: 'Ideation' },
    { name: 'Design Thinking', id: 'Design Thinking' },
    { name: 'Opportunity Validation', id: 'Opportunity Validation' },
    { name: 'Marketing', id: 'Marketing' },
    { name: 'Financial', id: 'Financial' },
    { name: 'Presentation Skills', id: 'Presentation Skills' },
  ]),
  course: createSortedSelectOptions([
    { name: 'BizCamp', id: 'BizCamp' },
    { name: 'EE', id: 'EE' },
    { name: 'E1', id: 'E1' },
    { name: 'E2', id: 'E2' },
    { name: 'Start It Up! ', id: 'Start It Up! ' },
    { name: 'ESB Prep', id: 'ESB Prep' },
    { name: 'Startup Tech', id: 'Startup Tech' },
  ]),
  industryFocus: createSortedSelectOptions([
    { name: 'STEM', id: 'STEM' },
    { name: 'Social entrepreneurship', id: 'Social entrepreneurship' },
    { name: 'Financial Literacy', id: 'Financial Literacy' },
    { name: 'Marketing', id: 'Marketing' },
  ]),
  stages: [
    { value: 'Pre-ideation', label: 'Pre-ideation' },
    { value: 'Ideation', label: 'Ideation' },
    { value: 'Validation', label: 'Validation' },
    { value: 'Prototype', label: 'Prototype' },
    { value: 'Pitch', label: 'Pitch' },
    { value: 'Fundraising', label: 'Fundraising' },
    { value: 'Launch', label: 'Launch' },
  ],
};
