import ArticleIcon from '@mui/icons-material/Article';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DescriptionIcon from '@mui/icons-material/Description';
import QuizIcon from '@mui/icons-material/Quiz';
import organizationAPI from 'api/api';
import {
  MediaPlayerProgressBar,
  ObjectiveScoreCard,
  QuestionRaised,
  ScoreCard,
  Transcript,
} from 'components/CustomMUI';
import LoadingOverlay from 'components/LoadingOverlay';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store';
import usePersonaStore from 'store/personaStore';
import useNotificationStore from 'store/useNotificationStore';
import styles from './styles/Style.module.css';
const CallDetails = () => {
  const [activeComponent, setActiveComponent] = useState('scorecard');
  const { docId, clearDocId } = usePersonaStore();
  const [responseData, setResponseData] = useState();
  const [peerComparisonData, setPeerComparisonData] = useState();
  const [transcriptData, setTranscriptData] = useState();
  const [detailsGenerateTime, setDetailsGenerateTime] = useState('');
  const user = useAuthStore((state) => state.loggedUser);

  const { setNotification } = useNotificationStore();
  const navigate = useNavigate();
  const [audioUrl, setAudioUrl] = useState(null);

  const { call: transcriptCall } = useApi({
    fetcher: organizationAPI.getConvRubric,
    successMessage: 'Call Details Fetched Successfully!',
  });

  const { call: peerResponseCall } = useApi({
    fetcher: organizationAPI.getPeerReponses,
  });

  const { call: getTranscriptCall } = useApi({
    fetcher: organizationAPI.getCallTranscript,
  });

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchScoreboardData = async () => {
      try {
        setLoading(true);
        const response = await transcriptCall(docId);

        if (response) {
          const date = new Date(response.data.createdAt);
          const options = {
            hour: 'numeric',
            minute: 'numeric',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour12: true,
          };
          const formattedDate = date.toLocaleString('en-US', options);
          setDetailsGenerateTime(formattedDate);
          setResponseData(response.data);
        } else {
          setLoading(false);
          setNotification(
            'There is no data available for this call. Please try again.',
            'error'
          );
          clearDocId();
          navigate(-1);
        }
      } catch (error) {
        setNotification(
          'Something went wrong. Please try again. ' || error,
          'error'
        );
        clearDocId();
        navigate(`/${String(user.role).toLocaleLowerCase()}/leaderboard`);
      } finally {
        setLoading(false);
      }
    };
    const fetchPeerResponseData = async () => {
      try {
        setLoading(true);
        const response = await peerResponseCall(docId);
        setPeerComparisonData(response.data.result);
        if (response.data.audioUrl) {
          setAudioUrl(response.data.audioUrl);
        }
        setLoading(false);
      } catch (error) {
        setNotification(
          'Something went wrong. Please try again. ' || error,
          'error'
        );
        clearDocId();
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
    const fetchTranscriptData = async () => {
      try {
        setLoading(true);
        const response = await getTranscriptCall(docId);
        setTranscriptData(response.data.messages);
        setLoading(false);
      } catch (error) {
        setNotification(
          'Something went wrong. Please try again. ' || error,
          'error'
        );
        clearDocId();
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
    fetchScoreboardData();
    fetchTranscriptData();
    fetchPeerResponseData();
  }, [docId]);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? <LoadingOverlay></LoadingOverlay> : null}
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.navbar_container}>
            <div className={styles.navbar}>
              {user && (
                <h1>
                  Call Details:{' '}
                  {responseData && responseData.userName
                    ? responseData.userName
                    : user.firstName + ' ' + user.lastName}{' '}
                  , {detailsGenerateTime}
                </h1>
              )}

              <div className={styles.nav_right}>
                <button className={styles.nav_btn2} onClick={handleBackClick}>
                  <ChevronLeftIcon />
                  <p>Return</p>
                </button>
              </div>
            </div>
            <div className={styles.border}></div>
          </div>
          <div className={styles.media_player}>
            {audioUrl ? (
              <MediaPlayerProgressBar audioSrc={audioUrl} width="50%" />
            ) : (
              <h3 className={styles.no_audio}>Audio file is not available</h3>
            )}
          </div>
          <div className={styles.container}>
            <div className={styles.nav_container}>
              <div className={styles.nav}>
                <button
                  className={styles.nav_item}
                  style={{
                    borderBottom:
                      activeComponent === 'scorecard'
                        ? '3px solid #00706c'
                        : 'none',
                  }}
                  onClick={() => setActiveComponent('scorecard')}
                >
                  <DescriptionIcon />
                  <p>Scorecard</p>
                </button>
                <button
                  className={styles.nav_item}
                  style={{
                    borderBottom:
                      activeComponent === 'questionraised'
                        ? '3px solid #00706c'
                        : 'none',
                  }}
                  onClick={() => setActiveComponent('questionraised')}
                >
                  <QuizIcon />
                  <p>Questions Raised</p>
                </button>
                <button
                  className={styles.nav_item}
                  style={{
                    borderBottom:
                      activeComponent === 'transcript'
                        ? '3px solid #00706c'
                        : 'none',
                  }}
                  onClick={() => setActiveComponent('transcript')}
                >
                  <ArticleIcon />
                  <p>Transcript</p>
                </button>
              </div>
              <div className={styles.line} />
            </div>
            {activeComponent === 'questionraised' ? (
              <QuestionRaised data={peerComparisonData} audioUrl={audioUrl} />
            ) : activeComponent === 'transcript' ? (
              <Transcript data={transcriptData} />
            ) : (
              <ScoreCard data={responseData} />
            )}
          </div>
          {responseData && (
            <ObjectiveScoreCard data={responseData.objectiveScoreCard} />
          )}
        </div>
      </div>
    </>
  );
};

export default CallDetails;
