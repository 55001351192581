import apis from 'api/api';
import debounce from 'lodash/debounce';
import { useCallback, useEffect } from 'react';
import useApi from './useApi';

export const useDuplicateEmailChecker = ({ setError }) => {
  const { call: checkDuplicateEmail, isLoading: isChecking } = useApi({
    fetcher: apis.checkDuplicateEmail,
  });

  const checkEmail = useCallback(
    debounce(async ({ email }) => {
      if (!email) return;
      const res = await checkDuplicateEmail({ email });
      if (res?.data?.duplicate) {
        setError('email', {
          type: 'manual',
          message: 'This email is already taken',
        });
      }
    }, 500),
    []
  );

  useEffect(() => {
    return () => {
      checkEmail.cancel();
    };
  }, [checkEmail]);

  return { checkEmail, isChecking };
};
