import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, IconButton, Typography } from '@mui/material';
import { FlexibleTable } from 'components/CustomMUI';
import dateFormat from 'dateformat';
import { useNavigate } from 'react-router-dom';
import usePersonaStore from 'store/personaStore';
import {
  difficultyColors,
  moodColors,
  toneColors,
} from 'utils/const/status-color';

const StatusBadge = ({ text, colorMap }) => (
  <Typography
    color={colorMap[String(text).toLowerCase()] || colorMap.default}
    fontSize={12}
    fontWeight={500}
    fontFamily="Lexend"
    noWrap
    sx={{
      width: 'fit-content',
      padding: '1px 8px',
      border: `1px solid ${
        colorMap[String(text).toLowerCase()] || colorMap.default
      }`,
      borderRadius: '20px',
    }}
  >
    {text}
  </Typography>
);

const CallHistoryTable = ({ data = [], onViewCall }) => {
  const tableData = data.map((item) => ({
    ...item,
    onView: () => onViewCall?.(item),
  }));

  const { setDocId } = usePersonaStore();
  const navigate = useNavigate();

  const columns = [
    {
      header: 'Persona/Recipient',
      key: 'title',
      sortable: true,
    },
    {
      header: 'Recipient Type',
      key: 'type',
      sortable: true,
      getValue: () => 'AI',
    },
    {
      header: 'Date',
      key: 'createdAt',
      sortable: true,
      render: (value) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <CalendarTodayIcon />
          <Typography>{dateFormat(value, 'mmm d, yyyy')}</Typography>
        </Box>
      ),
    },
    {
      header: 'Duration',
      key: 'duration',
      sortable: true,
      render: (value) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <LocalPhoneIcon />
          <Typography>{value}</Typography>
        </Box>
      ),
    },
    {
      header: 'Mood',
      key: 'mood',
      sortable: true,
      render: (value) => <StatusBadge text={value} colorMap={moodColors} />,
    },
    {
      header: 'Tone',
      key: 'tone',
      sortable: true,
      render: (value) => <StatusBadge text={value} colorMap={toneColors} />,
    },
    {
      header: 'Difficulty',
      key: 'difficulty',
      sortable: true,
      render: (value) => (
        <StatusBadge text={value} colorMap={difficultyColors} />
      ),
    },
    {
      header: '',
      key: 'actions',
      render: (_, row) => {
        const handleCallDetails = () => {
          setDocId(row.id);
          navigate('/sales-representative/call-details');
        };

        return (
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <IconButton onClick={handleCallDetails}>
              <VisibilityIcon sx={{ color: '#00706C' }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return <FlexibleTable data={tableData} columns={columns} />;
};

export default CallHistoryTable;
