import {
  Edit,
  Group,
  MoreVert,
  PeopleOutline,
  PersonOutline,
} from '@mui/icons-material';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import organizationAPI from 'api/api';
import { FlexibleTable } from 'components/CustomMUI';
import dateFormat from 'dateformat';
import { useApi } from 'hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from 'store/useStore';
import EditAssignment from '../Drawer/EditAssignment';

const renderAssignees = (assignees) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,

        maxWidth: '500px',
        overflow: 'auto',
        textWrap: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      {assignees.map((assignee, index) => (
        <Box
          key={index}
          sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
        >
          {assignee.type === 'user' && (
            <PersonOutline sx={{ fontSize: 16, color: 'text.secondary' }} />
          )}
          {assignee.type === 'team' && (
            <PeopleOutline sx={{ fontSize: 16, color: 'text.secondary' }} />
          )}
          <Typography variant="body2" component="span">
            {assignee.userName}
          </Typography>
          {/* {assignee.type && (
            <Typography
              variant="body2"
              color="text.secondary"
              component="span"
              sx={{
                backgroundColor:
                  assignee.type === 'user' ? '#A8E8FCFF' : '#8D8EFFFF',
                padding: '1px 6px',
                borderRadius: '12px',
              }}
            >
              {assignee.type}
            </Typography>
          )} */}
          {index < assignees.length - 1 && (
            <Typography variant="body2" color="text.secondary" component="span">
              +
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

const AssignmentsTable = ({ data, filter, search }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [assignmentId, setAssignmentId] = useState(null);
  const { render, setRender } = useStore();

  const { call } = useApi({
    fetcher: organizationAPI.deleteAssignmnet,
    successMessage: 'Assignment deleted successfully',
  });

  const handleMenuOpen = (event, row) => {
    setSelectedAssignment(row);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedAssignment(null);
  };

  const handleEditDrawer = () => {
    setAnchorEl(null);
    setDrawerOpen(true);
  };

  const handleRemoveClick = () => {
    setRemoveDialogOpen(true);
    handleMenuClose();
  };

  const handleRemoveConfirm = async () => {
    await call(assignmentId);
    if (!render) {
      setRender();
    }
    setRemoveDialogOpen(false);
    setSelectedAssignment(null);
    setAssignmentId(null);
  };

  const handleNavigateToAssignmentDetails = (id) => {
    setAnchorEl(null);
    setSelectedAssignment(null);
    navigate(`/assignment/details/${id}`);
  };

  const filteredData = data
    .filter((row) => filter === 'all' || filter.includes(row.status))
    .filter((row) => {
      if (!search && filter === 'all') return true;

      const searchLower = search.toLowerCase();
      const assignmentNameValid = row.assignmentName
        ? row.assignmentName.toLowerCase().includes(searchLower)
        : false;
      const dueDateValid = row.dueDate
        ? row.dueDate.toLowerCase().includes(searchLower)
        : false;
      const assigneesValid =
        row.assignedTo &&
        row.assignedTo.some((assignee) =>
          assignee.userName.toLowerCase().includes(searchLower)
        );

      return assignmentNameValid || dueDateValid || assigneesValid;
    });

  const columns = [
    { header: 'Assignment Name', key: 'assignmentName', sortable: true },
    {
      header: 'Assigned to',
      key: 'assignedTo',
      render: (value) => renderAssignees(value),
      sortable: true,
    },
    {
      header: 'Due Date',
      key: 'dueDate',
      getValue: (row) => dateFormat(row.dueDate, 'mmm d, yyyy'),
      sortable: true,
    },
    {
      header: 'Status',
      key: 'status',
      sortable: false,
      render: (value) => (
        <Typography
          sx={{
            backgroundColor:
              value === 'all'
                ? 'rgba(128, 128, 128, 0.5)'
                : value === 'assigned'
                ? 'rgba(64, 153, 255, 0.5)'
                : value === 'active'
                ? 'rgba(0, 200, 117, 0.5)'
                : value === 'completed'
                ? 'rgba(241, 217, 110, 0.5)'
                : value === 'overdue'
                ? 'rgba(253, 97, 97, 0.5)'
                : value === 'archived'
                ? 'rgba(128, 128, 128, 0.5)'
                : 'rgba(80, 80, 80, 0.1)',
            padding: '1px 10px',
            borderRadius: '12px',
            width: 'fit-content',
            textTransform: 'capitalize',
          }}
        >
          {value}
        </Typography>
      ),
    },
    {
      header: '',
      key: 'actions',
      sortable: false,
      render: (_, row) => (
        <IconButton onClick={(event) => handleMenuOpen(event, row)}>
          <MoreVert />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Paper
        sx={{
          boxShadow: 'none',
          border: '1px solid #DAE8E7',
          borderRadius: '8px',
          padding: '10px',
        }}
      >
        <FlexibleTable
          data={filteredData}
          columns={columns}
          tableStyles={{
            tableContainer: {
              boxShadow: 'none',
              border: 'none',
            },
          }}
        />
      </Paper>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() =>
            handleNavigateToAssignmentDetails(selectedAssignment._id)
          }
        >
          <Group sx={{ mr: 1, fontSize: 20, my: 1 }} />
          View Assignees
        </MenuItem>
        <MenuItem onClick={handleEditDrawer}>
          <Edit sx={{ mr: 1, fontSize: 20, my: 1 }} />
          Edit Assignment
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleRemoveClick();
            setAssignmentId(selectedAssignment._id);
          }}
          sx={{ color: 'error.main', my: 1 }}
        >
          <Box
            component="span"
            sx={{
              width: 20,
              height: 20,
              mr: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            🚫
          </Box>
          Remove
        </MenuItem>
      </Menu>

      <Dialog
        open={removeDialogOpen}
        onClose={() => setRemoveDialogOpen(false)}
        PaperProps={{
          sx: { borderRadius: 2, maxWidth: 400, paddingY: '10px' },
        }}
      >
        <DialogTitle sx={{ textAlign: 'center', pt: 3 }}>
          <Box
            component="span"
            sx={{
              fontSize: '2rem',
              display: 'block',
              mb: 1,
            }}
          >
            <PersonOffIcon sx={{ color: '#FF0000FF', fontSize: '60px' }} />
          </Box>
          Remove Assignment
        </DialogTitle>
        <DialogContent>
          <Typography align="center" sx={{ mb: 3 }}>
            Are you sure you want to remove this assignment? This action cannot
            be undone.
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              fullWidth
              variant="contained"
              color="error"
              onClick={handleRemoveConfirm}
            >
              Yes
            </Button>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setRemoveDialogOpen(false)}
            >
              No
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: { width: { xs: '100%', sm: 1100 } },
        }}
      >
        {selectedAssignment && (
          <EditAssignment
            onClose={() => setDrawerOpen(false)}
            id={selectedAssignment._id}
          />
        )}
      </Drawer>
    </>
  );
};
export default AssignmentsTable;
