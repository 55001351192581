import { ChevronLeft, ChevronRightOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
const CurvedLine = () => (
  <svg
    width="18"
    height="145"
    viewBox="0 0 18 145"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <rect width="18" height="144" fill="white" />
    <path
      d="M17 1V2.186C17 4.308 16.1571 6.343 14.6569 7.843L3.34314 19.157C1.84285 20.657 1 22.692 1 24.814L1 122.186C1 124.308 1.84285 126.343 3.34315 127.843L14.6569 139.157C16.1571 140.657 17 142.692 17 144.814V145"
      stroke="#8B9190FF"
      className="mix-blend-multiply"
    />
  </svg>
);

const VerticalToggleButton = ({
  onClick,
  isExpanded,
  showLabel,
  hideLabel,
}) => (
  <Box
    onClick={onClick}
    sx={{
      position: 'absolute',
      left: isExpanded ? 'calc(50% - 9px)' : -9,
      top: 0,
      bottom: 0,
      width: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      zIndex: 10,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 8,
        width: 1,
        backgroundColor: '#C9CCCBFF',
      },
      '&:hover': {
        '& .MuiSvgIcon-root': {
          color: '#000',
        },
      },
    }}
  >
    <Box sx={{ position: 'relative' }}>
      <CurvedLine />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            writingMode: 'vertical-rl',
            transform: 'rotate(180deg)',
            fontSize: '8px',
            fontWeight: 300,
            color: isExpanded ? '#8CA19F' : '#00706C',
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          {isExpanded ? hideLabel : showLabel}
        </Typography>
        {isExpanded ? (
          <ChevronLeft
            sx={{
              color: isExpanded ? '#8CA19F' : '#00706C',
              fontSize: '15px',
              mt: '4px',
              zIndex: 1,
            }}
          />
        ) : (
          <ChevronRightOutlined
            sx={{
              color: isExpanded ? '#8CA19F' : '#00706C',
              fontSize: '15px',
              mt: '4px',
              zIndex: 1,
            }}
          />
        )}
      </Box>
    </Box>
  </Box>
);
export default VerticalToggleButton;
