import ArchiveIcon from '@mui/icons-material/Archive';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Chip,
  Drawer,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import apis from 'api/api';
import { FormFields } from 'components/CustomMUI';

import { useLoggedUser } from 'contexts/UserContext';
import { useApi } from 'hooks';
import { useEffect, useState } from 'react';
import { useConvoDashboardStore } from 'store';

export const SalesRepEditDialog = () => {
  const { user } = useLoggedUser();
  const [managers, setManagers] = useState([]);
  const updateSalesRepItem = useConvoDashboardStore(
    (state) => state.updateSalesRepItem
  );
  const dialogState = useConvoDashboardStore((state) => state.editDialog);
  const changeDialogState = useConvoDashboardStore(
    (state) => state.setEditDialog
  );
  const { data } = dialogState;

  const { call: updateUser, isLoading: isUpdating } = useApi({
    fetcher: apis.updateUser,
    successMessage: `user info updated successfully`,
  });

  const { call: getUserByRole } = useApi({
    fetcher: apis.getUserByRole,
  });

  const {
    call: getTeams,
    data: teams,
    isLoading: teamsLoading,
  } = useApi({
    fetcher: apis.getConvoTeam,
  });

  const handleClose = () => {
    changeDialogState({ isOpen: false, forItem: 'admin' });
  };

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    assignedTeams: [],
    products: [],
    geography: [],
    managers: [],
    salesTypes: {
      smb: false,
      midMarket: false,
      enterprise: false,
    },
  });

  useEffect(() => {
    if (data) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        email: data.email || '',
        assignedTeams: data.assignedTeams || [],
        products: data.products || [],
        geography: data.geography || [],
        managers: data.managers || [],
        salesTypes: {
          ...prevFormData.salesTypes,
          ...data.salesTypes,
        },
      }));
    }
  }, [data]);

  useEffect(() => {
    const fetchUser = async () => {
      const response = await getUserByRole({
        orgId: user.organization,
        role: 'ADMIN',
      });

      setManagers(response.data);
    };

    fetchUser();
    getTeams(user.organization);
  }, []);

  const handleChange = (field, newValue) => {
    setFormData((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const handleSalesTypeChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      salesTypes: {
        ...prev.salesTypes,
        [event.target.name]: event.target.checked,
      },
    }));
  };

  const updateSRInfo = async () => {
    const filteredFormData = {
      ...formData,
      assignedTeams: formData.assignedTeams.map(({ id, name }) => ({
        id,
        name,
      })),
      managers: formData.managers.map(({ id, name }) => ({ id, name })),
    };

    await updateUser({
      userId: data._id,
      organizationId: user.organization,
      data: filteredFormData,
    });

    updateSalesRepItem({ _id: data._id, ...filteredFormData });
    changeDialogState({ isOpen: false, forItem: 'admin' });
  };

  return (
    <Drawer
      anchor="right"
      open={dialogState.isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '800px',
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 4,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          Edit Sales Representative
        </Typography>
        <Box>
          <Button
            startIcon={<CloseIcon />}
            onClick={handleClose}
            sx={{
              mr: 1,
              color: '#5B706F',
              border: '1px solid #5B706F',
              borderRadius: '5px',
              p: '6px 15px',
              '&:hover': { backgroundColor: '#D9DAD9FF' },
            }}
          >
            Discard
          </Button>
          <Button
            startIcon={<CheckIcon />}
            onClick={updateSRInfo}
            sx={{
              mr: 1,
              color: '#00706C',
              border: '1px solid #00706C',
              borderRadius: '5px',
              p: '6px 15px',
              '&:hover': { backgroundColor: '#01B1ABFF', color: '#fff' },
            }}
          >
            Save
          </Button>
        </Box>
      </Box>

      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
      >
        <Box>
          <FormFields
            type="textfield"
            name="firstName"
            label="First Name"
            formData={formData}
            handleChange={handleChange}
          />
        </Box>

        <Box>
          <FormFields
            type="textfield"
            name="lastName"
            label="Last Name"
            formData={formData}
            handleChange={handleChange}
          />
        </Box>

        <Box>
          <FormFields
            type="textfield"
            name="email"
            label="Email Address"
            formData={formData}
            handleChange={handleChange}
          />
        </Box>

        <Box>
          {teams && (
            <FormFields
              type="taginput"
              name="assignedTeams"
              label="Assigned Teams"
              formData={formData}
              handleChange={handleChange}
              options={teams.map((team) => ({
                id: team._id,
                name: `${team.name}`,
              }))}
              tagInput={false}
              props={{
                getOptionLabel: (option) => option.name || '',
                renderTags: (value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={option.name}
                      sx={{
                        backgroundColor: '#DAE8E7',
                        m: 0.5,
                        '& .MuiChip-deleteIcon': {
                          color: '#5B706F',
                        },
                      }}
                    />
                  )),
                renderInput: (params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Assigned Teams"
                    placeholder="Select Assigned Teams"
                  />
                ),
              }}
            />
          )}
        </Box>

        <Box>
          <FormFields
            type="taginput"
            name="products"
            label="Products"
            formData={formData}
            handleChange={handleChange}
            options={[]}
            tagInput={true}
            props={{
              renderTags: (value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={option}
                    sx={{
                      backgroundColor: '#DAE8E7',
                      m: 0.5,
                      '& .MuiChip-deleteIcon': {
                        color: '#5B706F',
                      },
                    }}
                  />
                )),
              renderInput: (params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Products"
                  placeholder="Type Product Name..."
                />
              ),
            }}
          />
        </Box>

        <Box>
          <FormFields
            type="taginput"
            name="geography"
            label="Geography"
            formData={formData}
            handleChange={handleChange}
            options={[]}
            tagInput={true}
            props={{
              renderTags: (value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={option}
                    sx={{
                      backgroundColor: '#DAE8E7',
                      m: 0.5,
                      '& .MuiChip-deleteIcon': {
                        color: '#5B706F',
                      },
                    }}
                  />
                )),
              renderInput: (params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Geography"
                  placeholder="Type new location..."
                />
              ),
            }}
          />
        </Box>

        <Box>
          {managers && (
            <FormFields
              type="taginput"
              name="managers"
              label="Managers"
              formData={formData}
              handleChange={handleChange}
              options={managers.map((manager) => ({
                id: manager.id,
                name: `${manager.firstName} ${manager.lastName}`,
              }))}
              tagInput={false}
              props={{
                getOptionLabel: (option) => option.name || '',
                renderTags: (value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={option.name}
                      sx={{
                        backgroundColor: '#DAE8E7',
                        m: 0.5,
                        '& .MuiChip-deleteIcon': {
                          color: '#5B706F',
                        },
                      }}
                    />
                  )),
                renderInput: (params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Managers"
                    placeholder="Type manager name"
                  />
                ),
              }}
            />
          )}
        </Box>

        <Box>
          <FormGroup>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormFields
                type="checkbox"
                name="smb"
                label="SMB"
                formData={formData.salesTypes}
                handleChange={(name, value) =>
                  handleSalesTypeChange({ target: { name, checked: value } })
                }
                props={{}}
              />
              <FormFields
                type="checkbox"
                name="midMarket"
                label="Mid-Market"
                formData={formData.salesTypes}
                handleChange={(name, value) =>
                  handleSalesTypeChange({ target: { name, checked: value } })
                }
                props={{}}
              />
              <FormFields
                type="checkbox"
                name="enterprise"
                label="Enterprise"
                formData={formData.salesTypes}
                handleChange={(name, value) =>
                  handleSalesTypeChange({ target: { name, checked: value } })
                }
                props={{}}
              />
            </Box>
          </FormGroup>
        </Box>
      </Box>
      <Button
        variant="outlined"
        color="error"
        startIcon={<ArchiveIcon />}
        sx={{
          mt: 'auto',
          mb: 5,
          color: '#FF4444',
          borderColor: '#FF4444',
          '&:hover': {
            borderColor: '#CC0000',
            backgroundColor: '#CC0000',
            color: '#fff',
          },
        }}
      >
        Archive Sales Representative
      </Button>
    </Drawer>
  );
};
