import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const FORMAT_STRING = 'MMMM do, yyyy'; // "October 7th, 2023"

export const parseDate = (dateString) => {
  // Remove any ordinal suffixes (like 'th', 'nd', 'rd', 'st')
  const formattedDate = dateString.replace(/(\d+)(th|nd|rd|st)/, '$1');
  return new Date(formattedDate);
};

export const getOriginalZonedDate = ({
  date,
  formatString = FORMAT_STRING,
}) => {
  if (!date) return 'INVALID DATE';
  const utcDate = utcToZonedTime(date);

  const formattedDate = format(utcDate, formatString);
  return formattedDate;
};
