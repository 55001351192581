export const areModuleArraysEqual = (arr1 = [], arr2 = []) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((module1) => {
    const matchingModule = arr2.find(
      (module2) => module2.moduleId === module1.moduleId
    );
    if (!matchingModule) {
      return false;
    }

    const sections1 = new Set(module1.sections);
    const sections2 = new Set(matchingModule.sections);

    if (sections1.size !== sections2.size) {
      return false;
    }

    return [...sections1].every((section) => sections2.has(section));
  });
};
