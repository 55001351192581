export const languageOptions = [
  { label: 'Afrikaans', value: 'Afrikaans' },
  { label: 'Albanian', value: 'Albanian' },
  { label: 'Amharic', value: 'Amharic' },
  { label: 'Arabic', value: 'Arabic' },
  { label: 'Armenian', value: 'Armenian' },
  { label: 'Azerbaijani', value: 'Azerbaijani' },
  { label: 'Basque', value: 'Basque' },
  { label: 'Belarusian', value: 'Belarusian' },
  { label: 'Bengali', value: 'Bengali' },
  { label: 'Bosnian', value: 'Bosnian' },
  { label: 'Bulgarian', value: 'Bulgarian' },
  { label: 'Burmese', value: 'Burmese' },
  { label: 'Catalan', value: 'Catalan' },
  { label: 'Cebuano', value: 'Cebuano' },
  { label: 'Chichewa', value: 'Chichewa' },
  { label: 'Chinese', value: 'Chinese' },
  { label: 'Corsican', value: 'Corsican' },
  { label: 'Croatian', value: 'Croatian' },
  { label: 'Czech', value: 'Czech' },
  { label: 'Danish', value: 'Danish' },
  { label: 'Dutch', value: 'Dutch' },
  { label: 'English', value: 'English' },
  { label: 'Esperanto', value: 'Esperanto' },
  { label: 'Estonian', value: 'Estonian' },
  { label: 'Filipino', value: 'Filipino' },
  { label: 'Finnish', value: 'Finnish' },
  { label: 'French', value: 'French' },
  { label: 'Galician', value: 'Galician' },
  { label: 'Georgian', value: 'Georgian' },
  { label: 'German', value: 'German' },
  { label: 'Greek', value: 'Greek' },
  { label: 'Gujarati', value: 'Gujarati' },
  { label: 'Haitian Creole', value: 'Haitian Creole' },
  { label: 'Hausa', value: 'Hausa' },
  { label: 'Hawaiian', value: 'Hawaiian' },
  { label: 'Hebrew', value: 'Hebrew' },
  { label: 'Hindi', value: 'Hindi' },
  { label: 'Hmong', value: 'Hmong' },
  { label: 'Hungarian', value: 'Hungarian' },
  { label: 'Icelandic', value: 'Icelandic' },
  { label: 'Igbo', value: 'Igbo' },
  { label: 'Indonesian', value: 'Indonesian' },
  { label: 'Irish', value: 'Irish' },
  { label: 'Italian', value: 'Italian' },
  { label: 'Japanese', value: 'Japanese' },
  { label: 'Javanese', value: 'Javanese' },
  { label: 'Kannada', value: 'Kannada' },
  { label: 'Kazakh', value: 'Kazakh' },
  { label: 'Khmer', value: 'Khmer' },
  { label: 'Kinyarwanda', value: 'Kinyarwanda' },
  { label: 'Korean', value: 'Korean' },
  { label: 'Kurdish', value: 'Kurdish' },
  { label: 'Kyrgyz', value: 'Kyrgyz' },
  { label: 'Lao', value: 'Lao' },
  { label: 'Latin', value: 'Latin' },
  { label: 'Latvian', value: 'Latvian' },
  { label: 'Lithuanian', value: 'Lithuanian' },
  { label: 'Luxembourgish', value: 'Luxembourgish' },
  { label: 'Macedonian', value: 'Macedonian' },
  { label: 'Malagasy', value: 'Malagasy' },
  { label: 'Malay', value: 'Malay' },
  { label: 'Malayalam', value: 'Malayalam' },
  { label: 'Maltese', value: 'Maltese' },
  { label: 'Maori', value: 'Maori' },
  { label: 'Marathi', value: 'Marathi' },
  { label: 'Mongolian', value: 'Mongolian' },
  { label: 'Nepali', value: 'Nepali' },
  { label: 'Norwegian', value: 'Norwegian' },
  { label: 'Odia', value: 'Odia' },
  { label: 'Pashto', value: 'Pashto' },
  { label: 'Persian', value: 'Persian' },
  { label: 'Polish', value: 'Polish' },
  { label: 'Portuguese', value: 'Portuguese' },
  { label: 'Punjabi', value: 'Punjabi' },
  { label: 'Romanian', value: 'Romanian' },
  { label: 'Russian', value: 'Russian' },
  { label: 'Samoan', value: 'Samoan' },
  { label: 'Scots Gaelic', value: 'Scots Gaelic' },
  { label: 'Serbian', value: 'Serbian' },
  { label: 'Sesotho', value: 'Sesotho' },
  { label: 'Shona', value: 'Shona' },
  { label: 'Sindhi', value: 'Sindhi' },
  { label: 'Sinhala', value: 'Sinhala' },
  { label: 'Slovak', value: 'Slovak' },
  { label: 'Slovenian', value: 'Slovenian' },
  { label: 'Somali', value: 'Somali' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'Sundanese', value: 'Sundanese' },
  { label: 'Swahili', value: 'Swahili' },
  { label: 'Swedish', value: 'Swedish' },
  { label: 'Tajik', value: 'Tajik' },
  { label: 'Tamil', value: 'Tamil' },
  { label: 'Tatar', value: 'Tatar' },
  { label: 'Telugu', value: 'Telugu' },
  { label: 'Thai', value: 'Thai' },
  { label: 'Turkish', value: 'Turkish' },
  { label: 'Turkmen', value: 'Turkmen' },
  { label: 'Ukrainian', value: 'Ukrainian' },
  { label: 'Urdu', value: 'Urdu' },
  { label: 'Uyghur', value: 'Uyghur' },
  { label: 'Uzbek', value: 'Uzbek' },
  { label: 'Vietnamese', value: 'Vietnamese' },
  { label: 'Welsh', value: 'Welsh' },
  { label: 'Xhosa', value: 'Xhosa' },
  { label: 'Yiddish', value: 'Yiddish' },
  { label: 'Yoruba', value: 'Yoruba' },
  { label: 'Zulu', value: 'Zulu' },
];
