import { createSortedSelectOptions } from 'utils';

export const journeyFields = [
  {
    name: 'title',
    trName: 'trTitle',
    label: 'Journey Title',
    type: 'text',
  },
  {
    name: 'category',
    trName: 'trCategory',
    label: 'Journey Category',
    type: 'text',
  },
  {
    name: 'course',
    trName: 'trCourse',
    label: 'Course',
    type: 'multiSelect',
    autoComplete: true,
  },
  {
    name: 'deliveryMethod',
    trName: 'trDeliveryMethod',
    label: 'Delivery Method',
    type: 'multiSelect',
    autoComplete: true,
  },
  {
    name: 'description',
    trName: 'trDescription',
    label: 'Description',
    type: 'text',
    multiline: true,
  },
  {
    name: 'status',
    trName: 'trStatus',
    label: 'Status',
    type: 'text',
  },
  {
    name: 'stages',
    trName: 'trStages',
    label: 'Stages',
    type: 'multiSelect',
    autoComplete: true,
  },
  {
    name: 'spaceOwnership',
    trName: 'trSpaceOwnership',
    label: 'Space Ownership',
    type: 'text',
  },
  {
    name: 'playbookType',
    trName: 'trPlaybookType',
    label: 'Playbook Type',
    type: 'text',
  },
  {
    name: 'viewPlaybookList',
    trName: 'trViewPlaybookList',
    label: 'Ventures Who Can See This Playbook',
    type: 'multiSelect',
    autoComplete: true,
  },
  {
    name: 'industryFocus',
    trName: 'trIndustryFocus',
    label: 'IndustryFocus',
    type: 'multiSelect',
    autoComplete: true,
  },
  {
    name: 'location',
    trName: 'trLocation',
    label: 'Location',
    type: 'multiSelect',
    autoComplete: true,
  },
  {
    name: 'pillar',
    trName: 'trPillar',
    label: 'Pillar',
    type: 'multiSelect',
    autoComplete: true,
  },
];

export const languageOptions = createSortedSelectOptions([
  { name: 'Chinese', id: 'Chinese' },
  { name: 'English', id: 'English' },
  { name: 'Spanish', id: 'Spanish' },
  { name: 'Arabic', id: 'Arabic' },
  { name: 'French', id: 'French' },
  { name: 'Persian', id: 'Persian' },
  { name: 'German', id: 'German' },
  { name: 'Russian', id: 'Russian' },
  { name: 'Malay', id: 'Malay' },
  { name: 'Portuguese', id: 'Portuguese' },
  { name: 'Italian', id: 'Italian' },
  { name: 'Turkish', id: 'Turkish' },
  { name: 'Lahnda', id: 'Lahnda' },
  { name: 'Tamil', id: 'Tamil' },
  { name: 'Urdu', id: 'Urdu' },
  { name: 'Korean', id: 'Korean' },
  { name: 'Hindi', id: 'Hindi' },
  { name: 'Bengali', id: 'Bengali' },
  { name: 'Japanese', id: 'Japanese' },
  { name: 'Vietnamese', id: 'Vietnamese' },
  { name: 'Telugu', id: 'Telugu' },
  { name: 'Marathi', id: 'Marathi' },
]);
