import { yupResolver } from '@hookform/resolvers/yup';
import GridOnIcon from '@mui/icons-material/GridOn';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Stack, Typography } from '@mui/material';
import LoadingOverlay from 'components/LoadingOverlay';
import { useLoggedUser } from 'contexts/UserContext';
import { useDuplicateEmailChecker } from 'hooks/useDuplicateEmailCheck';
import { BulkCSVInviteModal } from 'pages/Dashboard/Dialog/BulkInvite/BulkInviteModal';
import { parseBulkInviteFile } from 'pages/Dashboard/Dialog/BulkInvite/utils/parseBulkInviteFile';
import {
  useCourseContext,
  useDialog,
  useUserContext,
} from 'pages/Settings/context';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { colorPalette } from 'theme/colors';
import { FormField } from '../common';
import { DisabledRoleOptions } from '../common/components/DisabledRoleOptions';
import { schema } from './validation/schema';

export const blankStudentInfo = {
  firstName: '',
  lastName: '',
  email: '',
  role: 'STUDENT',
  course: '',
  region: '',
};

export const StudentAdd = () => {
  const { user } = useLoggedUser();

  const [userInfo, setUserInfo] = useState(blankStudentInfo);
  const { post, isPosting } = useUserContext();

  const {
    isFetching: courseIsFetching,
    regions,
    schools,
    options: courseOptions,
  } = useCourseContext();

  const { isDialogOpen, closeDialog, dialogData, openBulkInviteDialog } =
    useDialog();
  const hiddenInputRef = useRef(null);

  const {
    register,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
    watch,
    trigger,
    setError,
  } = useForm({
    resolver: yupResolver(schema({ type: 'ADD' })),
    mode: 'onChange',
    defaultValues: { role: 'STUDENT' },
  });

  const { checkEmail, isChecking: isEmailChecking } = useDuplicateEmailChecker({
    setError,
  });
  const handleStudentSubmit = async (data) => {
    const selectedCourse = courseOptions.filter((o) => o.value === data.course);
    const courseName = selectedCourse[0]?.label || '';
    await post({ ...data, course: courseName });
    setUserInfo(blankStudentInfo);
    reset();
  };

  function renderFormFields() {
    const fields = [
      [
        { name: 'firstName', label: 'First Name', type: 'text' },
        { name: 'lastName', label: 'Last Name', type: 'text' },
        { name: 'email', label: 'Email', type: 'text' },
      ],
      [
        {
          name: 'region',
          label: 'Region',
          type: 'select',
          options: regions,
        },
        {
          name: 'school',
          label: 'School',
          type: 'select',
          options: schools,
        },
        {
          name: 'course',
          label: 'Course',
          type: 'select',
          options: courseOptions,
        },
      ],
    ];

    const commonProps = {
      formType: 'ADD',
      register,
      errors,
      watch,
      state: userInfo,
      stateUpdater: setUserInfo,
      hookFormUpdater: setValue,
      hookFormTrigger: trigger,
    };

    const renderStack = (fieldGroup) =>
      fieldGroup.map((field) => (
        <FormField
          loading={
            courseIsFetching || (field.name === 'email' && isEmailChecking)
          }
          disabled={field.name === 'role'}
          key={field.name}
          field={field}
          onChange={(e) => {
            if (field.name === 'email') {
              checkEmail({ email: e.target.value });
            }
          }}
          {...commonProps}
        />
      ));

    return {
      firstStack: renderStack(fields[0]),
      secondStack: renderStack(fields[1]),
    };
  }

  const invitationButtons = (
    <>
      <Button
        variant="contained"
        color="success"
        startIcon={<SendIcon />}
        type="submit"
        disabled={isEmailChecking || !!errors.email}
      >
        Send Invite
      </Button>
      <Button
        variant="contained"
        color="success"
        startIcon={<GridOnIcon />}
        onClick={() => {
          hiddenInputRef.current.click();
        }}
      >
        Bulk Invite
      </Button>

      <input
        id="csvInput"
        type="file"
        accept=".csv"
        style={{ display: 'none' }}
        ref={hiddenInputRef}
        onChange={(e) => {
          parseBulkInviteFile(e, (parsedData) => {
            if (parsedData) {
              openBulkInviteDialog({ data: parsedData });
            }
          });
        }}
      />
    </>
  );

  return (
    <Box
      sx={{
        background: colorPalette.background.paper,
        px: 2,
        py: 4,
        borderRadius: 2,
      }}
      component="form"
      onSubmit={handleSubmit(handleStudentSubmit)}
    >
      {isPosting ? <LoadingOverlay /> : null}
      <Stack direction="column" spacing={4}>
        <Typography variant="h6">Invite Personnel</Typography>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {renderFormFields().firstStack}
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <DisabledRoleOptions selectedRole={'STUDENT'} />
          {renderFormFields().secondStack}
          {invitationButtons}

          {dialogData && dialogData.length ? (
            <BulkCSVInviteModal
              data={dialogData}
              open={isDialogOpen.bulkInvite && dialogData.length}
              closeDialog={() => {
                closeDialog();
              }}
              user={user}
            />
          ) : null}
        </Stack>
      </Stack>
    </Box>
  );
};

export default StudentAdd;
