import { yupResolver } from '@hookform/resolvers/yup';
import { Checklist, SpaceDashboard } from '@mui/icons-material';
import apis from 'api/api';
import EditDialogWrapper from 'components/Dialog/DialogWrapper';
import { Tab } from 'components/Tab';
import { useApi } from 'hooks';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDialogStore, useItemStore } from 'store';
import { cleanObject, handleExportPPTX, StoreKeys } from 'utils';
import { ModuleDetails } from '../ModuleDetails';
import ModuleSection from '../ModuleSection';
import { moduleInfoSchema } from './schema';

export const EditDialog = ({ forItem = '' }) => {
  const updateEditDialogState = useDialogStore((state) => state.setEditDialog);
  const dialogState = useDialogStore((state) => state.editDialog);
  const itemStore = useItemStore((state) => state.items);

  const updateItemStore = useItemStore((state) => state.updateItem);

  const methods = useForm({
    resolver: yupResolver(moduleInfoSchema),
    mode: 'onChange',
  });

  const { call: updateModule } = useApi({
    fetcher: apis.updateModule,
    successMessage: 'Module updated successfully',
  });

  const { call: generatePPT } = useApi({
    fetcher: apis.generatePPT,
    successMessage: 'Presentation generated successfully',
  });

  const handleEdit = (value) => {
    const cleanData = cleanObject(value);
    const keysToRemove = [
      'organizationId',
      'userId',
      'createdAt',
      'sections',
      'isAiGenerated',
      '__v',
    ];

    const dataToUpdate = Object.fromEntries(
      Object.entries(cleanData).filter(([key]) => !keysToRemove.includes(key))
    );

    updateModule({
      data: dataToUpdate,
      moduleId: cleanData._id,
    });

    const updatedList = itemStore.modules.map((module) =>
      module._id === cleanData._id ? { ...module, ...cleanData } : module
    );

    updateItemStore({ key: StoreKeys.MODULES, value: updatedList });

    updateEditDialogState({ data: cleanData });
    methods.reset();
  };

  const TabItems = useMemo(
    () => [
      {
        label: 'Module Details',
        value: 'module_details',
        icon: <Checklist />,
        content: <ModuleDetails />,
      },
      {
        label: 'Module Sections',
        value: 'module_sections',
        icon: <SpaceDashboard />,
        content: <ModuleSection moduleId={dialogState?.data?._id} />,
      },
    ],
    []
  );

  return (
    <FormProvider {...methods}>
      <EditDialogWrapper
        forItem={forItem}
        onSubmit={methods.handleSubmit(handleEdit)}
        sx={{ width: '75vw' }}
        onClose={() => methods.reset()}
        isExportable={true}
        onExport={() =>
          handleExportPPTX({
            id: dialogState?.data?._id,
            title: dialogState?.data?.title,
            call: generatePPT,
          })
        }
      >
        <Tab tabItems={TabItems} />
      </EditDialogWrapper>
    </FormProvider>
  );
};
