import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useEffect, useState } from 'react';

const ImageUpload = ({ onImageChange, previewImage = null }) => {
  const [uploadedImage, setUploadedImage] = useState(null);

  useEffect(() => {
    if (previewImage) {
      setUploadedImage(previewImage);
    }
  }, [previewImage]);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const base64String = await convertToBase64(file);
        setUploadedImage(base64String);
        onImageChange({ base64: base64String, file });
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    }
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <Box
        sx={{
          border: '1px solid #DAE8E7',
          borderRadius: '8px',
          padding: '30px 10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '40px',
        }}
      >
        {uploadedImage && (
          <Avatar
            src={uploadedImage}
            sx={{
              width: 90,
              height: 90,
              cursor: 'pointer',
              borderRadius: '8px',
            }}
            onClick={() => onImageChange(uploadedImage)}
          />
        )}

        <label htmlFor="upload-image">
          <Box
            sx={{
              width: 90,
              height: 90,
              cursor: 'pointer',
              border: '1px dashed #00706C',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AddPhotoAlternateIcon sx={{ fontSize: '74px' }} />
          </Box>
          <input
            id="upload-image"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleImageUpload}
          />
        </label>
      </Box>
      <InputLabel
        sx={{
          position: 'absolute',
          left: '10px',
          top: '-25px',
          backgroundColor: '#fff',
          paddingRight: '10px',
          fontSize: '14px',
          fontWeight: '400',
          fontFamily: 'Lexend',
          color: '#243030',
        }}
      >
        SELECT A TEAM AVATAR
      </InputLabel>
    </FormControl>
  );
};

export default ImageUpload;
