import { ExpandMore } from '@mui/icons-material';
import TranslateIcon from '@mui/icons-material/Translate';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Typography,
} from '@mui/material';
import apis from 'api/api';
import {
  DynamicLoadingChecklist,
  FormFields,
  VerticalToggleButton,
} from 'components/CustomMUI';
import { useApi } from 'hooks';
import { languageOptions } from 'pages/Journey/common/languages';
import ModuleContent from 'pages/Journey/ModuleContent';
import { useEffect, useRef, useState } from 'react';

const modules = [
  {
    _id: '67a231def81f5eb3214cbf56',
    title: 'Break Even Analysis',
    sections: [
      {
        title: 'Mindset Warm-ups',
        activity: 'Future Orientation and Decision Making',
        minutes: 30,
        subSections: [
          {
            title: 'Students who are future oriented',
            description:
              'Encourages students to express and pursue long-term goals.',
            content:
              'This subsection focuses on helping students articulate their career and business interests, seek out relevant information, and set long-term goals. It emphasizes the importance of investing in the present for future gains and developing patience for delayed gratification.',
            _id: '67a231def81f5eb3214cbf58',
          },
          {
            title: 'Think and respond to this question',
            description: "Analyzing Sal's financial decision-making.",
            content:
              'Sal just opened a deli. After selling sandwiches for a week, he has $5,000 left after variable expenses. Sal gets so excited that he spends almost all of this money immediately. Do you think this was a good decision? Is Sal thinking with a ‘short-term’ or ‘long-term’ view? Explain your answer. Be prepared to share your thoughts with the class.',
            _id: '67a231def81f5eb3214cbf59',
          },
          {
            title: 'Think and respond to this question',
            description: "Analyzing Sal's financial decision-making.",
            content:
              'Sal just opened a deli. After selling sandwiches for a week, he has $5,000 left after variable expenses. Sal gets so excited that he spends almost all of this money immediately. Do you think this was a good decision? Is Sal thinking with a ‘short-term’ or ‘long-term’ view? Explain your answer. Be prepared to share your thoughts with the class.',
            _id: '67a231def81f5eb3214cbf5a',
          },
        ],
        _id: '67a231def81f5eb3214cbf57',
      },
    ],
  },
  {
    _id: '67a231e3f81f5eb3214cbf5c',
    status: 'draft',
    title: 'Break Even Analysis AI',
    sections: [
      {
        title: 'Introduction to Break Even Analysis',
        activity: 'Understanding the Basics of Break Even Analysis',
        minutes: 30,
        subSections: [
          {
            title: 'Definition and Importance',
            description:
              'An overview of what break even analysis is and why it is important.',
            content:
              'Break even analysis is a financial calculation to determine the point at which revenue received equals the costs associated with receiving the revenue. It is crucial for businesses to understand their break even point to make informed financial decisions.',
            _id: '67a231e3f81f5eb3214cbf5e',
          },
          {
            title: 'Key Components',
            description:
              'Identifying the main components involved in break even analysis.',
            content:
              'The key components of break even analysis include fixed costs, variable costs, and sales price per unit. Understanding these components helps in calculating the break even point accurately.',
            _id: '67a231e3f81f5eb3214cbf5f',
          },
        ],
        _id: '67a231e3f81f5eb3214cbf5d',
      },
      {
        title: 'Calculating Break Even Point',
        activity: 'Learning to Calculate Break Even Point',
        minutes: 45,
        subSections: [
          {
            title: 'Formula and Calculation',
            description:
              'Explaining the formula used to calculate the break even point.',
            content:
              'The break even point can be calculated using the formula: Break Even Point (in units) = Fixed Costs / (Sales Price per Unit - Variable Cost per Unit). This formula helps in determining the number of units that need to be sold to cover all costs.',
            _id: '67a231e3f81f5eb3214cbf61',
          },
          {
            title: 'Practical Example',
            description:
              'A practical example to illustrate the calculation of break even point.',
            content:
              'Consider a company with fixed costs of $10,000, a sales price per unit of $50, and a variable cost per unit of $30. The break even point would be 500 units, calculated as $10,000 / ($50 - $30).',
            _id: '67a231e3f81f5eb3214cbf62',
          },
        ],
        _id: '67a231e3f81f5eb3214cbf60',
      },
      {
        title: 'Applications of Break Even Analysis',
        activity: 'Exploring the Applications of Break Even Analysis',
        minutes: 35,
        subSections: [
          {
            title: 'Decision Making',
            description:
              'How break even analysis aids in business decision making.',
            content:
              'Break even analysis helps businesses in making critical decisions such as pricing strategies, budgeting, and financial planning. It provides insights into the minimum sales required to avoid losses.',
            _id: '67a231e3f81f5eb3214cbf64',
          },
          {
            title: 'Risk Assessment',
            description:
              'Using break even analysis for assessing business risks.',
            content:
              'By understanding the break even point, businesses can assess the risk of new ventures or products. It helps in evaluating the financial viability and potential profitability of business decisions.',
            _id: '67a231e3f81f5eb3214cbf65',
          },
        ],
        _id: '67a231e3f81f5eb3214cbf63',
      },
    ],
  },
  {
    _id: '67a231dbf81f5eb3214cbf50',
    title: 'UPD upd',
    sections: [
      {
        title: 'Introduction to Leadership',
        activity: 'Leadership Basics',
        minutes: 30,
        minuteLabel: 'minutes',
        subSections: [
          {
            title: 'Understanding Leadership',
            description:
              'An overview of what leadership entails and its importance.',
            content:
              'Leadership is the ability to guide, influence, and inspire individuals or groups to achieve common goals. It involves setting a vision, motivating people, and creating an environment where everyone can contribute to the success of the organization.',
            _id: '67a231dbf81f5eb3214cbf52',
          },
          {
            title: 'Key Leadership Traits',
            description:
              'Exploring essential traits that make an effective leader.',
            content:
              'Effective leaders possess traits such as integrity, empathy, resilience, and the ability to communicate clearly. These traits help leaders build trust, foster collaboration, and drive positive change within their teams.',
            _id: '67a231dbf81f5eb3214cbf53',
          },
          {
            title: 'Leadership Styles',
            description: 'Different styles of leadership and their impact.',
            content:
              'Leadership styles vary from autocratic to democratic, transformational to transactional. Each style has its strengths and weaknesses, and effective leaders adapt their style to suit the needs of their team and the situation.',
            _id: '67a231dbf81f5eb3214cbf54',
          },
        ],
        _id: '67a231dbf81f5eb3214cbf51',
      },
    ],
  },
];

const TestPage = () => {
  const [showOriginal, setShowOriginal] = useState(true);
  const moduleRef = useRef(null);
  const [translation, setTranslation] = useState({
    language: '',
  });

  const [sortedData, setSortedData] = useState([]);

  const [data, setData] = useState([]);

  const { call: createJourneyModuleTranslation, isLoading: isTranslating } =
    useApi({
      fetcher: apis.createJourneyModuleTranslation,
    });

  const { call: getTranslatedModules, isLoading: isFetchingTranslation } =
    useApi({
      fetcher: apis.getTranslatedModules,
    });

  const loading = isTranslating || isFetchingTranslation;

  const [checklistItems, setChecklistItems] = useState([]);

  useEffect(() => {
    setChecklistItems([
      {
        id: 'generateTranslation',
        label: `Generating Translation From English To ${translation.language}`,
        isComplete: false,
        apiCall: createJourneyModuleTranslation,
      },
      {
        id: 'getTranslation',
        label: `Fetching ${translation.language} Translation`,
        isComplete: false,
        apiCall: getTranslatedModules,
      },
    ]);
  }, [translation.language]);

  const updateChecklistItem = (itemId, isComplete) => {
    setChecklistItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isComplete } : item
      )
    );
  };

  const toggleOriginal = () => setShowOriginal(!showOriginal);

  const handleChange = (field, newValue, setValue) => {
    setValue((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const extractActivities = (module) => {
    const activities = [];
    if (module?.sections) {
      module.sections.forEach((section) => {
        if (section.activity && section.minutes) {
          activities.push({
            name: section.activity,
            duration: section.minutes.toString(),
          });
        }
      });
    }
    return activities;
  };

  useEffect(() => {
    const processedData =
      !data || data.length === 0
        ? modules.map((module) => ({
            originalModule: module,
            translatedModule: {},
          }))
        : [...data].sort((a, b) =>
            a.originalModule.title.localeCompare(b.originalModule.title)
          );

    setSortedData(processedData);
  }, [data]);

  const handleTranslate = async () => {
    const tempData = {
      language: translation.language,
    };
    const translateResponse = await checklistItems[0].apiCall({
      data: tempData,
      journeyId: '67a231caf81f5eb3214cbcf0',
    });

    updateChecklistItem('generateTranslation', true);

    const translatedResponse = await checklistItems[1].apiCall(
      '67a231caf81f5eb3214cbcf0'
    );
    updateChecklistItem('getTranslation', true);

    setData(translatedResponse.data);
  };

  return (
    <Box
      sx={{
        p: 3,
        bgcolor: '#fff',
        minHeight: '100vh',
        border: '1px solid #DAE8E7',
        borderRadius: '8px',
      }}
    >
      <DynamicLoadingChecklist
        isLoading={loading}
        checklistItems={checklistItems}
        title={`Translating data. This may take a few minutes.`}
      />
      <Typography
        variant="h6"
        sx={{ mb: 2, fontWeight: 300, fontSize: '20px' }}
      >
        Translation System
      </Typography>
      <Divider sx={{ border: '1px solid #dae8e7', my: '10px', mb: '30px' }} />

      <Box
        sx={{
          mb: 4,
          gap: 2,
          display: 'flex',
          flexDirection: 'column',
          border: 'none',
        }}
      >
        {modules &&
          modules.length > 0 &&
          modules.map((module, index) => (
            <Accordion
              key={index}
              sx={{
                boxShadow: 'none',
                border: '1px solid #DAE8E7',
                borderRadius: '8px',
                '&::before': { display: 'none' },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>
                  Module {index + 1 + `: `}: {module.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 4, border: 'none' }}>
                {module.sections.map((section, sIndex) => (
                  <Box key={sIndex} sx={{ pl: 2, mb: 2 }}>
                    <Typography sx={{ fontWeight: 500, mb: 1 }}>
                      Section {index + 1 + `: `} {section.title}
                    </Typography>
                    <Box
                      sx={{
                        ml: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}
                    >
                      {section.subSections.map((sm, smIndex) => (
                        <Typography
                          key={smIndex}
                          variant="body2"
                          color="text.secondary"
                        >
                          SM {index + 1 + `: `} {section.title}
                          {sm.title}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            width: '100%',
            height: 50,
          }}
        >
          <FormFields
            type="select"
            name="language"
            label="TARGET LANGUAGE"
            formData={translation}
            canSearch={true}
            handleChange={(name, value) =>
              handleChange(name, value, setTranslation)
            }
            options={languageOptions}
            props={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 400,
                    overflowY: 'auto',
                  },
                },
              },
            }}
          />
        </Box>
        <Button
          variant="outlined"
          color="primary"
          sx={{ minWidth: 180, height: 55 }}
          startIcon={<TranslateIcon />}
          onClick={handleTranslate}
        >
          Translate
        </Button>
      </Box>

      {sortedData.length > 0 && (
        <Box
          sx={{ display: 'flex', position: 'relative', alignItems: 'start' }}
          ref={moduleRef}
        >
          <Box
            sx={{ flex: 1, pr: 3, display: showOriginal ? 'block' : 'none' }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,

                justifyContent: 'start',
              }}
            >
              {sortedData &&
                sortedData.length > 0 &&
                sortedData.map((item, index) => (
                  <ModuleContent
                    title={`Module ${index + 1}`}
                    activities={extractActivities(item.originalModule)}
                    setActivities={(newActivities) => {
                      const updatedData = [...sortedData];
                      updatedData[index].originalModule.sections = updatedData[
                        index
                      ].originalModule.sections.map((section, sIndex) => ({
                        ...section,
                        activity:
                          newActivities[sIndex]?.name || section.activity,
                        minutes:
                          parseInt(newActivities[sIndex]?.duration) ||
                          section.minutes,
                      }));
                      setData(updatedData);
                    }}
                    removeActivity={(activityIndex) => {
                      const updatedData = [...sortedData];
                      updatedData[index].originalModule.sections.splice(
                        activityIndex,
                        1
                      );
                      setData(updatedData);
                    }}
                    isTranslated={false}
                    data={item.originalModule}
                    onChange={(field, value) =>
                      handleChange(field, value, setData)
                    }
                    disabled={true}
                    key={`original-${index}`}
                  />
                ))}
            </Box>
          </Box>

          <VerticalToggleButton
            onClick={toggleOriginal}
            isExpanded={showOriginal}
            hideLabel={'HIDE ORIGINAL'}
            showLabel={'SHOW ORIGINAL'}
          />

          <Box sx={{ flex: 1, pl: showOriginal ? 3 : 3 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'start',
              }}
            >
              {sortedData &&
                sortedData.length > 0 &&
                sortedData.map((item, index) => (
                  <ModuleContent
                    title={`Module ${index + 1}`}
                    activities={extractActivities(item.translatedModule)}
                    setActivities={(newActivities) => {
                      const updatedData = [...sortedData];
                      updatedData[index].translatedModule.sections =
                        updatedData[index].translatedModule.sections.map(
                          (section, sIndex) => ({
                            ...section,
                            activity:
                              newActivities[sIndex]?.name || section.activity,
                            minutes:
                              parseInt(newActivities[sIndex]?.duration) ||
                              section.minutes,
                          })
                        );
                      setData(updatedData);
                    }}
                    removeActivity={(activityIndex) => {
                      const updatedData = [...sortedData];
                      updatedData[index].translatedModule.sections.splice(
                        activityIndex,
                        1
                      );
                      setData(updatedData);
                    }}
                    isTranslated={true}
                    data={item.translatedModule}
                    onChange={(field, value) =>
                      handleChange(field, value, setData)
                    }
                    disabled={false}
                    key={`translated-${index}`}
                  />
                ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TestPage;
