import { Box, Divider, Stack, Typography } from '@mui/material';
import { UserProvider, useUserContext } from 'pages/Settings/context';
import { FormField } from 'pages/Settings/Tabs/common';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDialogStore } from 'store/useStore';
import { countryOptions } from 'utils/const/countries';
import { languageOptions } from './common/const';

export const JourneyDetails = () => {
  const editDialogState = useDialogStore((state) => state.editDialog);
  const [isTitleManuallyEdited, setIsTitleManuallyEdited] = useState(false);

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    trigger,
    reset,
  } = useFormContext();

  const {
    data: partnerList,
    get: getPartnerList,
    isFetching: fetchingPartner,
  } = useUserContext();

  const get11Years = () => {
    let years = [];
    let currentYear = new Date().getFullYear();
    for (let i = 0; i < 11; i++) {
      years.push({ value: currentYear + i, label: currentYear + i });
    }
    return years;
  };

  useEffect(() => {
    getPartnerList();
  }, []);

  useEffect(() => {
    const year = watch('year');
    const partner = watch('partnerName');
    const language = watch('language');
    if (
      !isTitleManuallyEdited &&
      year &&
      partner &&
      language &&
      !editDialogState?.data?.title
    ) {
      setValue('title', `${partner}-${year}-${language}`);
    }
  }, [watch('partnerName'), watch('year'), watch('language'), watch('title')]);

  useEffect(() => {
    reset(editDialogState?.data);
  }, [editDialogState?.data]);

  const renderFormFields = () => {
    const infoFields = [
      {
        name: 'partnerId',
        label: 'Partner',
        type: 'select',
        options: partnerList?.map((partner) => ({
          value: partner.id,
          label: `${partner.firstName} ${partner.lastName}`,
        })),
      },
      {
        name: 'description',
        label: 'Description',
        type: 'text',
        multiline: true,
      },
    ];

    const settingsFields = [
      [
        {
          name: 'year',
          label: 'Year',
          type: 'select',
          options: get11Years(),
        },
        {
          name: 'duration',
          label: 'Journey Duration (months)',
          type: 'number',
        },

        {
          name: 'numberOfHoursPerMeeting',
          label: 'Hours Per Meeting',
          type: 'number',
        },
      ],
      [
        {
          name: 'formatOfInstruction',
          label: 'Format of Instruction',
          type: 'select',
          options: [
            { value: 'Virtual', label: 'Virtual Instruction' },
            { value: 'In-Person', label: 'In-person Instruction' },
          ],
        },
        {
          name: 'supportStructure',
          label: 'Support Structure',
          type: 'select',
          options: [
            { label: 'Facilitated', value: 'Facilitated' },
            { label: 'Independent', value: 'Independent' },
          ],
        },

        {
          name: 'location',
          label: 'Location',
          type: 'multiSelect',
          options: countryOptions,
          autoComplete: true,
        },
        {
          name: 'language',
          label: 'Target Language',
          type: 'select',
          options: languageOptions,
        },
      ],
      {
        name: 'additionalInstructions',
        label: 'Additional Instruction Prompts',
        type: 'text',
        multiline: true,
      },
      { name: 'title', label: 'Journey Title', type: 'text' },
    ];

    return (
      <>
        {infoFields.map((item, index) => (
          <FormField
            loading={fetchingPartner && item.name === 'partnerId'}
            formType="ADD"
            key={`${item.name}-${index}`}
            field={item}
            register={register}
            errors={errors}
            watch={watch}
            hookFormUpdater={setValue}
            hookFormTrigger={trigger}
            onChange={(e) => {
              if (item.name === 'partnerId') {
                const partner = partnerList.find(
                  (item) => item.id === e.target.value
                );
                const partnerName = `${partner.firstName} ${partner.lastName}`;
                setValue('partnerName', partnerName);
              }
            }}
          />
        ))}
        <Typography variant="button">Module Settings</Typography>
        <Divider sx={{ borderColor: 'primary.main' }} />

        {settingsFields.map((item, index) => {
          // Check if the current item is an array
          const isChunkOfFields = Array.isArray(item);
          if (isChunkOfFields) {
            return (
              <Stack
                direction={'row'}
                spacing={2}
                key={`${item.name}-${index}`}
              >
                {item.map((nestedItem) => (
                  <FormField
                    formType="ADD"
                    key={`${nestedItem.name}-${index}`}
                    field={nestedItem}
                    register={register}
                    errors={errors}
                    watch={watch}
                    hookFormUpdater={setValue}
                    hookFormTrigger={trigger}
                  />
                ))}
              </Stack>
            );
          } else {
            return (
              <FormField
                formType="ADD"
                key={`settings-${item.name}`}
                field={item}
                register={register}
                errors={errors}
                watch={watch}
                hookFormUpdater={setValue}
                hookFormTrigger={trigger}
                onChange={(e) => {
                  if (item.name === 'title') {
                    if (e.target.value !== '') setIsTitleManuallyEdited(true);
                    else setIsTitleManuallyEdited(false);
                  }
                }}
              />
            );
          }
        })}
      </>
    );
  };

  return (
    <UserProvider userType={'PARTNER'}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {renderFormFields()}
      </Box>
    </UserProvider>
  );
};
