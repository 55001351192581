import apis from 'api/api';
import { useApi } from 'hooks';
import { useDialogStore, useItemStore } from 'store';
import { cleanObject, StoreKeys } from 'utils';

export const useJourney = () => {
  const itemStore = useItemStore((state) => state.items);
  const updateEditDialogState = useDialogStore((state) => state.setEditDialog);
  const updateItemStore = useItemStore((state) => state.updateItem);

  const { call: updateJourney, isLoading: isJourneyUpdating } = useApi({
    fetcher: apis.updateJourney,
    successMessage: 'Journey updated successfully',
  });

  const editJourney = async (value) => {
    const cleanData = cleanObject(value);
    const keysToRemove = [
      'organizationId',
      'userId',
      'createdAt',
      'sections',
      'modules',
      'aiModuleId',
      'targetLanguage',
      'translatedModule',
      '__v',
    ];

    const dataToUpdate = Object.fromEntries(
      Object.entries(cleanData).filter(([key]) => !keysToRemove.includes(key))
    );

    await updateJourney({
      data: dataToUpdate,
      journeyId: cleanData._id,
    });

    const updatedList = itemStore[StoreKeys.JOURNEYS].map((journey) =>
      journey._id === cleanData._id ? { ...journey, ...cleanData } : journey
    );

    updateItemStore({ key: StoreKeys.JOURNEYS, value: updatedList });

    updateEditDialogState({ data: cleanData });
  };

  return { editJourney, isJourneyUpdating };
};
