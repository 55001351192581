import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Box, Button, Container, Divider, Typography } from '@mui/material';
import { EmptyState, FlexibleTable } from 'components/CustomMUI';
import { useLoggedUser } from 'contexts/UserContext';
import dateFormat from 'dateformat';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePersonaStore from 'store/personaStore';
import useBirdsEyeDataStore from 'store/useBirdsEyeStatisticStore';
import {
  difficultyColors,
  moodColors,
  toneColors,
} from 'utils/const/status-color';
import AIAnalysisDrawer from '../../../../components/CustomMUI/AIAnalysisDrawer';

const styles = {
  cellStyle: {
    width: '150px',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '16px',
  },
  innerCellStyle: {
    width: '100%',
    overflowX: 'auto',
  },
  headerText: {
    color: '#243030',
    fontFamily: 'Lexend',
    fontWeight: 300,
  },
  tealButton: {
    color: 'rgb(0, 128, 128)',
    borderColor: 'rgb(0, 128, 128)',
    '&:hover': {
      borderColor: 'rgb(0, 128, 128)',
      bgcolor: 'rgba(0, 128, 128, 0.1)',
    },
  },
};

const StatusBadge = ({ text, colorMap }) => (
  <Typography
    color={colorMap[String(text).toLowerCase()] || colorMap.default}
    fontSize={12}
    fontWeight={500}
    fontFamily="Lexend"
    noWrap
    sx={{
      width: 'fit-content',
      padding: '1px 8px',
      border: `1px solid ${
        colorMap[String(text).toLowerCase()] || colorMap.default
      }`,
      borderRadius: '20px',
    }}
  >
    {text}
  </Typography>
);

const columns = [
  { header: 'Caller', key: 'caller', sortable: true },
  { header: 'Call Type', key: 'callType', sortable: true },
  { header: 'Persona', key: 'personaName', sortable: true },
  {
    header: 'Mood',
    key: 'mood',
    sortable: true,
    render: (value) => <StatusBadge text={value} colorMap={moodColors} />,
  },
  {
    header: 'Tone',
    key: 'tone',
    sortable: true,
    render: (value) => <StatusBadge text={value} colorMap={toneColors} />,
  },
  {
    header: 'Difficulty',
    key: 'difficulty',
    sortable: true,
    render: (value) => <StatusBadge text={value} colorMap={difficultyColors} />,
  },
  {
    header: 'Score',
    key: 'score',
    sortable: true,
    getValue: (row) => `${row.scoredValue} / ${row.maxScore}`,
  },
  {
    header: 'Date',
    key: 'createdAt',
    sortable: true,
    getValue: (row) => dateFormat(row.createdAt, 'mmm d, yyyy'),
  },
  {
    header: '',
    key: '',
  },
];

const DateSelectDropdown = ({ title }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mb: 3,
        alignItems: 'center',
      }}
    >
      <Typography variant="h6" sx={{ ...styles.headerText, fontSize: '20px' }}>
        {title}
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Button
          variant="outlined"
          startIcon={<AutoAwesomeIcon />}
          onClick={() => setDrawerOpen(true)}
          sx={styles.tealButton}
        >
          AI Analysis
        </Button>
      </Box>
      <AIAnalysisDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
    </Box>
  );
};

const BirdsEyeStatistic = () => {
  const { setDocId } = usePersonaStore();
  const { user } = useLoggedUser();
  const navigate = useNavigate();
  const { statistics } = useBirdsEyeDataStore();

  const handleShowScorecard = (id) => {
    if (id) {
      setDocId(id);
      navigate('/airoleplaycall/calldetails');
    }
  };

  return (
    <Container maxWidth="xl" sx={{ p: 4, bgcolor: '#fff', height: '95vh' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
        <Typography
          variant="h4"
          sx={{
            ...styles.headerText,
            fontSize: '28px',
            letterSpacing: '-0.087px',
          }}
        >
          Birds-Eye Statistics Data
        </Typography>
        <Button
          startIcon={<ArrowBackIosIcon fontSize="small" />}
          sx={{
            border: '1px solid #00706C',
            padding: '6px 16px',
            color: '#00706C',
            bgcolor: '#fff',
            '&:hover': { bgcolor: '#DFFCFBFF' },
          }}
          onClick={() =>
            navigate(`/${String(user.role).toLocaleLowerCase()}/leaderboard`)
          }
        >
          Return
        </Button>
      </Box>

      <Divider sx={{ border: '1px solid #dae8e7', my: '10px', mb: '30px' }} />

      <Box
        sx={{
          p: 2,
          margin: '0 auto',
          width: '97%',
          border: '1px solid #DAE8E7',
          borderRadius: '8px',
        }}
      >
        <DateSelectDropdown title="Search Results" />
        {statistics.length > 0 ? (
          <FlexibleTable
            data={statistics}
            columns={columns}
            onRowAction={(row) => handleShowScorecard(row._id)}
          />
        ) : (
          <EmptyState />
        )}
      </Box>
    </Container>
  );
};

export default BirdsEyeStatistic;
